// src/redux/orderIdsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const orderIdsSlice = createSlice({
  name: 'orderIds',
  initialState: {
    orderIds: [],
  },
  reducers: {
    setOrderIds: (state, action) => {
      state.orderIds = action.payload;
    },
  },
});

export const { setOrderIds } = orderIdsSlice.actions;
export const selectOrderIds = (state) => state.orderIds.orderIds;
export default orderIdsSlice.reducer;
