import jQuery from 'jquery';
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// import store from "./store";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";

window.$ = jQuery;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
    </PersistGate>
    </Provider>
  </>
);
