import React, { useRef } from "react";
import Slider from "react-slick";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PrevArrow = (props) => (
  <div {...props} className="custom-arrow custom-prev">
    {/* Previous */}
  </div>
);

const NextArrow = (props) => (
  <div {...props} className="custom-arrow custom-next">
    {/* Next */}
  </div>
);

const SimpleSlickCarousel = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dotsColor: 'orange',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000, // Set the time between each slide in milliseconds (e.g., 3000ms = 3 seconds)
  };
  

  const cardData = [
    {
      title: "Roof Estimates",
      description:
        "Our team is adept at calculating the materials required for roofing projects. Whether it's a new installation or repairs, our roof take-off reports are thorough and precise.",
    },
    {
      title: "Gutter Estimates",
      description:
        "Proper drainage is crucial for any structure. MAT Estimates delivers detailed take-off reports for gutters, ensuring efficient water management and a reliable estimate for your project.",
    },
    {
      title: "Paint Estimates",
      description:
        "Achieve the perfect finish with our accurate paint take-off reports. We factor in the type of surface, paint quality, and other variables to provide a comprehensive estimate.",
    },
    {
      title: "Concrete Estimates",
      description:
        "From foundations to pavements, our concrete take-off reports cover all aspects of concrete work. Trust us to provide detailed estimates for materials.",
    },
    {
      title: "Siding Estimates",
      description:
        "Enhance the aesthetics and durability of your building with our siding take-off reports. We consider the type of siding, dimensions, and other specifications to deliver precise estimates.",
    },
    // Siding Estimates
    // Add more card data as needed
  ];

  return (
    <div
      className="service-slider"
      style={{ maxWidth: "800px", margin: "0 auto", position: "relative" }}
    >
      <Slider {...settings} ref={sliderRef}>
        {cardData.map((card, index) => (
          <div key={index}>
            <Card
              sx={{
                margin: "20px",
                textAlign: "center",
                padding: "5px",
                paddingTop: "40px",
                paddingBottom: "40px",
                borderRadius: "20px",
                height: "250px",
                border: "1px solid #F8FFD2",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
                "&:hover": {
                  backgroundColor: "#f36e21", // Change to the desired hover color
                  cursor: "pointer", // Optional: Change cursor style on hover
                },
              }}
            >
              <CardContent>
                <Typography fontWeight={"bold"} variant="h6" component="div">
                  {card.title}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", textAlign: "justify" }}
                  variant="body2"
                >
                  {card.description}
                </Typography> 
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
      <div
        className="custom-arrow custom-prev"
        onClick={() => sliderRef.current.slickPrev()}
        style={{
          position: "absolute",
          top: "50%",
          left: "-20px",
          cursor: "pointer",
        }}
      >
        <ArrowBackIosIcon />
      </div>
      <div
        className="custom-arrow custom-next"
        onClick={() => sliderRef.current.slickNext()}
        style={{
          position: "absolute",
          top: "50%",
          right: "-20px",
          cursor: "pointer",
        }}
      >
        <ArrowForwardIosIcon />
      </div>
    </div>
  );
};

export default SimpleSlickCarousel;
