// formSlice.js

import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  location: {},
  propertyType: "",
  properitySize: "",
  buildings: 0,
  auxOrMain: "",
  description: "",
  MATGutter: false,
  MATRoofα: false,
  MATSideX: false,
  MATRoof: false,
  MATPainter: false,
  MATConcrete: false,
  sitemap:null,
  bluePrint:null
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      // Action payload should contain updated form data
      return { ...state, ...action.payload };
    },
    // Add other actions if needed
  },
});

export const { updateFormData } = formSlice.actions;
// Selector function within the slice
export const selectFormData = (state) => state.form;
export default formSlice.reducer;
