import React from "react";

export default function BottomCard() {
  return (
    <>
      <div className="how-card-cont" style={{ marginBottom: "0px" }}>
        <div className="how-left">
          <p style={{ maxWidth: "600px" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "36px",
                //   marginBottom: "20px",
              }}
            >
              {" "}
              Sample of Roof <br />
              Estimating Services
            </span>{" "}
          </p>
        </div>
        <div className="how-right">
          <p style={{ textAlign: "left", maxWidth: "600px" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "36px",
                color: "black",
                //   marginBottom: "20px",
              }}
            >
              Download Sample
            </span>{" "}
            <br />
            <a href="https://api.matestimates.com/Sample_Report.pdf" target="_blank">
              <button
                style={{ fontWeight: "bold" }}
                // onClick={toggleContent}
                className="loc-btn"
              >
                Download
              </button>
            </a>
          </p>
        </div>
      </div>

      <div className="mob-cont">
        <div>
          <div className="how-left">
            <p className="left-p">
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "36px",
                  //   marginBottom: "20px",
                }}
              >
                {" "}
                Sample of Roof Estimating Services
              </span>{" "}
            </p>
          </div>
          <div className="how-right">
            <div
              className="right-p"
              style={{ margin: "0 auto", alignItems: "center" }}
            >
              <p>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "36px",
                    color: "black",
                    //   marginBottom: "20px",
                  }}
                >
                  Download Sample
                </span>{" "}
                <a
                  href="https://matestimates.com/samplereport.pdf"
                  target="_blank"
                >
                  <button
                    style={{ fontWeight: "bold" }}
                    // onClick={toggleContent}
                    className="loc-btn"
                  >
                    Download
                  </button>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
