import AboutCard from "../components/About/AboutCards";
import AboutUs from "../components/About/AboutUs";
import Slider from "../components/Slider/Slider";
import HowWeDo from "../components/About/HowWeDo";
import MiddleBar from "../components/About/MiddleBar";
import Bar from "../components/header/Bar";
import NavBar from "../components/navigation/NavBar";
import Footer from "../components/navigation/Footer";
import CatalogHeader from "../components/productCatalog/CatalogHeader";
import { useEffect } from "react";
import ScrollToTopOnRefresh from "../components/ScrollToTopOnRefresh";
import { Helmet } from "react-helmet";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "home > AboutUs";
  return (
    <>
    <Helmet>
        <title>
        About Us - Construction Cost Estimates Services in USA | MatEstimates
        </title>
        <meta
          name="description"
          content="As a dedicated estimation company We are offering material takeoff reports services in all states of the USA"
        />
      </Helmet>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto", color: "white" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>About Us</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>
      <AboutUs />
      <HowWeDo />

      <AboutCard />
      {/* <MiddleBar /> */}
      <CatalogHeader />
      <Slider />
      <Footer />
    </>
  );
}
