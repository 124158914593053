import "./style.css";
import { useState } from "react";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import profile1 from "../../assets/profile1.jpg";
import profile2 from "../../assets/profile2.jpg";

const slideContent = [
  {
    title: " Alex Swiftwood",
    description:
      "MAT Estimates delivers estimates with unparalleled accuracy, providing us the confidence we need for our projects. Their precision in calculations is truly commendable.",
    profile: profile2,
  },
  {
    title: "Olivia Starling",
    description:
      "The prompt delivery of estimation reports by MAT Estimates has been a game-changer for our project timelines. Their commitment to swift service has saved us valuable time consistently.",
    profile: profile1,
  },
  {
    title: "Mason Emberly",
    description:
      "The quality of estimation reports from MAT Estimates is exceptional. Well-organized, thorough, and insightful – their reports have become an indispensable asset to our decision-making process.",
    profile: profile2,
  },
  {
    title: "Ava Moonstone",
    description:
      "MAT Estimates not only offers top-notch reports but also provides excellent value for money. The investment in their services is justified by the accuracy and reliability of the estimates we receive.",
    profile: profile1,
  },
];

function App() {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        {/* <FaArrowRight /> */}
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        {/* <FaArrowLeft /> */}
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    dots: true,
    appendDots: (dots) => (
      <div style={{ bottom: "-50px", position: "absolute", width: "100%" }}>
        <ul style={{ margin: "0", padding: "0", textAlign: "center" }}>
          {dots}
        </ul>
      </div>
    ),
  };

  return (
    <div style={{ maxWidth: "1200px", margin: "auto", textAlign: "center",marginBottom:'70px' }}>
      <h1 style={{ marginTop: "40px", fontSize: "30px", fontWeight: "bold",color:'#f36e21' }}>
        What our Client says!
      </h1>
      {/* <p>Hello Here and there</p> */}
      <div className="slider-cont">
        <Slider {...settings}>
          {slideContent.map((content, idx) => (
            <>
              <div
                className={idx === imageIndex ? "slide activeSlide" : "slide"}
                key={idx}
                style={{ marginTop: idx === imageIndex ? "auto" : "90px" }}
              >
                <Card
                  className="card"
                  sx={{ mb: "50px", position: "relative", overflow: "visible" }}
                >
                  <CardContent sx={{ paddingBottom: "50px" }}>
                    <Typography
                      style={{
                        color: idx === imageIndex ? "white" : "inherit",
                      }}
                      variant="h5"
                      component="div"
                    >
                      {content.title}
                    </Typography>
                    <Typography
                      style={{
                        color: idx === imageIndex ? "white" : "inherit",
                        textAlign:'justify'
                      }}
                      variant="body2"
                    >
                      {content.description}
                    </Typography>
                  </CardContent>
                  <div
                    style={{
                      position: "absolute",
                      // marginTop:'10px',
                      bottom: "-25px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 100,
                    }}
                  >
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        // backgroundColor: "black",

                        // alignContent: "center",
                      }}
                    >
                      <img
                        style={{ borderRadius: "50%" }}
                        src={content.profile}
                        alt="profile"
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default App;
