// MyMapComponent.js
import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const libraries = ["places"];

const MyMapComponent = () => {
  const [mapTypeId, setMapTypeId] = useState("satellite"); // Default to satellite view
  const [markerPosition, setMarkerPosition] = useState({
    lat: 31.5497,
    lng: 74.3436,
  }); // Default to Lahore
  const [address, setAddress] = useState("");

  const onMarkerDragEnd = (e) => {
    setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      setAddress(selectedAddress);
      setMarkerPosition(latLng);
    } catch (error) {
      console.error("Error selecting place", error);
    }
  };

  const handlePickLocation = () => {
    console.log("Picked Location:", markerPosition);
  };

  useEffect(() => {
    // Additional logic to handle mapTypeId changes, if needed
  }, [mapTypeId]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCc1UvL8wAY16Q8A6wgq1MGfvRwpTS0zK8"
      libraries={libraries}
    >
      <div>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Search Places...",
                  className: "location-search-input",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                  };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        style,
                      })}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {/* JavaScript   */}
        <GoogleMap
          mapContainerStyle={{
            height: "400px",
            width: "100%",
          }}
          zoom={20}
          center={markerPosition}
          mapTypeId={mapTypeId} // Set the map type to satellite
          onClick={(e) =>
            setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          }
        >
          <Marker
            position={markerPosition}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
        </GoogleMap>

        <button onClick={handlePickLocation}>Pick Location</button>
      </div>
    </LoadScript>
  );
};

export default MyMapComponent;
