import React from "react";
import { Link } from "react-router-dom";

export default function ProductBottomCard() {
  return (
    <>
      <div className="how-card-cont" style={{ marginBottom: "0px" }}>
        <div className="how-left">
          <p style={{ maxWidth: "600px" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "36px",
                //   marginBottom: "20px",
              }}
            >
              {" "}
              Experience excellence <br />
              with just a click!
            </span>{" "}
          </p>
        </div>
        <div className="how-right">
          <p style={{ textAlign: "left", maxWidth: "600px" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "36px",
                color: "black",
                //   marginBottom: "20px",
              }}
            >
              Order Now
            </span>{" "}
            <br />
            <Link to="/">
              <button
                style={{ fontWeight: "bold" }}
                // onClick={toggleContent}
                className="loc-btn"
              >
                Order Now
              </button>
            </Link>
          </p>
        </div>
      </div>

      <div className="mob-cont">
        <div className="how-left">
          <p className="left-p">
            <span
              style={{
                fontWeight: "bold",
                fontSize: "36px",
                //   marginBottom: "20px",
              }}
            >
              {" "}
              Experience excellence <br />
              with just a click!
            </span>{" "}
          </p>
        </div>
        <div className="how-right">
          <p className="left-p">
            <span
              style={{
                fontWeight: "bold",
                fontSize: "36px",
                color: "black",
                //   marginBottom: "20px",
              }}
            >
              Order Now
            </span>{" "}
            <br />
            <Link to="/">
              <button
                style={{ fontWeight: "bold" }}
                // onClick={toggleContent}
                className="loc-btn"
              >
                Order Now
              </button>
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
