import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectFiles } from '../slices/filesSlice';

const YourComponent = () => {
  const [loading, setLoading] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const files = useSelector(selectFiles);
  const bluePrint = files.bluePrint;
  const siteMap = files.siteMap;

  console.log('files from test', bluePrint, siteMap)

  const handleCheckout = async () => {
    try {
      setLoading(true);
      const response = await axios.post('http://localhost:5000/api/checkout', {
        // Pass any necessary data in the request body
        items: [{ name: 'Product Name', price: 10.99, quantity: 1 }], // Example data
      });
      setCheckoutUrl(response.data.url);
    } catch (error) {
      console.error('Error:', error.response.data.error); // Log any errors
    } finally {
      setLoading(false);
    }
  };

  // Redirect to checkoutUrl when it's available
  if (checkoutUrl) {
    window.location.href = checkoutUrl;
  }

  return (
    <div>
      <button onClick={handleCheckout} disabled={loading}>
        {loading ? 'Processing...' : 'Checkout'}
      </button>
    </div>
  );
};

export default YourComponent;
