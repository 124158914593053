import React, { useState } from "react";
import Bar from "../header/Bar";
import NavBar from "../navigation/NavBar";
import Footer from "../navigation/Footer";
// import Form from "../InfoForm/Form";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import {
  Box,
  Button,
  Card,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { selectFormData, updateFormData } from "../../slices/formSlice";
import { useDispatch, useSelector } from "react-redux";
// import { useSelector } from 'react-redux';
import { selectFiles, setBluePrint } from "../../slices/filesSlice";
import { setImage } from "../../slices/locImageSlice";
import locPic from '../../assets/ConfirmLoc.png'

export default function EditProducts() {
  const baseData = useSelector(selectFormData);
  const blueNSite = useSelector(selectFiles);
  const [markerPosition, setMarkerPosition] = useState({
    lat: baseData?.location?.lat,
    lng: baseData?.location?.lng,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mapTypeId, setMapTypeId] = useState("satellite");

  const [formData, setFormData] = useState(baseData);

  // const libraries = ["places"];
  const CustomMarker = ({ position, text }) => (
    <div onClick={handlePickLocation}>
      {/* <p style={{backgroundColor:'grey'}}>{text}</p> */}
      <button
        onClick={handlePickLocation}
        style={{
          backgroundColor: "green",
          fontSize: "10px",
          fontWeight: "bold",
          // padding: "5px",
          // margin:'20px',
          borderRadius: "20px",
          height:'40px',
          width:'80px',
          color: "white",
        }}
      >
        Confirm
      </button>
    </div>
  );
  const onMarkerDragEnd = (e) => {
    setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    // setIsContentVisible(false)
  };

  const handlePickLocation = async () => {
    console.log("Picked Location:", markerPosition);
    // setFlag(true);
    // setFlag(true);
    const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${markerPosition.lat},${markerPosition.lng}&zoom=20&size=600x400&maptype=satellite&markers=${markerPosition.lat},${markerPosition.lng}&key=AIzaSyCc1UvL8wAY16Q8A6wgq1MGfvRwpTS0zK8`;
    dispatch(setImage(staticMapUrl));
    // console.log(staticMapUrl);
    const mapsLink = `https://www.google.com/maps?q=${markerPosition.lat},${markerPosition.lng}`;
    console.log(mapsLink);
    // setLocation({ ...location, link: mapsLink });
    handleNext();
  };
  const isAtLeastOneCheckboxChecked = () => {
    return Object.values(formData).some((value) => value === true);
  };
  const blue = blueNSite?.bluePrint;
  const site = blueNSite?.siteMap;
  console.log("Im form editing", blue, site);
  console.log("Im form editing", baseData);
  const [siteMap, setSiteMap] = useState(baseData?.sitemap);
  const [file, setFile] = useState(baseData?.bluePrint);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isAtLeastOneCheckboxChecked()) {
      //   alert("Please select at least one checkbox.");
      setErrorMessage("Please select at least one Service.");
      return;
    }
    const testing = {
      ...formData,
      //   location: location,
      bluePrint: file,
      sitemap: siteMap,
    };
    dispatch(updateFormData(testing));
    navigate("/cart");
    console.log("im from form", testing);
    setErrorMessage("");
    console.log(errorMessage);
  };
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSiteMap(file);
      toast.success("SiteMap Uploaded");
    }
  };
  const handleblueFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setBluePrint(file);
      setFile(file);
      toast.success("bluePrint updated");
    }
  };
  const a = "Cart > Edit";

  // stepper
  const steps = ["Location/BluePrint", "Products"];
  const [activeStep, setActiveStep] = React.useState(1);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const LocationIcon = locPic;
  return (
    <>
      <Bar />
      <NavBar />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto", color: "white" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Edit Order</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      <Box sx={{ maxWidth: "1200px", margin: "0 auto", minHeight: "60vh" }}>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              {/* <Button onClick={handleReset}>Reset</Button> */}
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                <ArrowBackIcon />
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {/* {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )} */}
              {activeStep === steps.length - 1 ? (
                <ArrowForwardIcon style={{ opacity: 0.4 }} />
              ) : (
                <>
                  <Button onClick={handleNext}>
                    <ArrowForwardIcon color="black" />
                  </Button>
                </>
              )}
              {/* <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? " gg " : "Next"}
              </Button> */}
            </Box>
          </React.Fragment>
        )}
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption"></Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
        <Card>
          {activeStep === 0 ? (
            <>
              {baseData?.location?.lat && baseData?.location?.lng ? (
                <>
                  <div>
                    <LoadScript
                      // googleMapsApiKey="AIzaSyBdz3QPUZHzSr7-IDT6WSwNQjPW6VRhSYA"
                      googleMapsApiKey="AIzaSyCc1UvL8wAY16Q8A6wgq1MGfvRwpTS0zK8"
                      // libraries={libraries}
                    >
                      <GoogleMap
                        mapContainerStyle={{
                          margin: "0 auto",
                          marginTop: "80px",
                          marginBottom: "50px",
                          height: "500px",
                          maxWidth: "1200px",
                        }}
                        zoom={20}
                        center={markerPosition}
                        mapTypeId={mapTypeId} // Set the map type to satellite
                        onClick={(e) =>
                          setMarkerPosition({
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          })
                        }
                      >
                        {/* <OverlayView
                          position={markerPosition}
                          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                          <CustomMarker
                            position={markerPosition}
                            text="Select Location"
                          />
                        </OverlayView> */}
                        <Marker
                          position={markerPosition}
                          draggable={true}
                          icon={LocationIcon}
                          onDragEnd={onMarkerDragEnd}
                          onClick={handlePickLocation}
                        >
                          {(props) => (
                            <CustomMarker {...props} text="Select location" />
                          )}
                        </Marker>
                      </GoogleMap>
                    </LoadScript>
                  </div>
                </>
              ) : (
                <div style={{display:'flex', alignItems:'center'}}>
                  Update Blue Print
                  <label className="loc-btn">
                    <span>Upload BluePrint</span>
                    <input
                      type="file"
                      accept=".pdf, .doc, .docx"
                      className="hidden"
                      onChange={handleblueFileChange}
                    />
                  </label>
                  {file && (
                    <p style={{ fontSize: "13px" }}>{`${file?.name.substring(
                      0,
                      4
                    )}.. ${
                      file?.name.includes(".")
                        ? `.${file?.name.split(".").pop()}`
                        : ""
                    }`}</p>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div
                style={{ maxWidth: "1100px", margin: "0 auto", zIndex: 100 }}
              >
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      // width: "90%", // Adjust the width as per your preference
                      margin: "0 auto",
                      marginTop: "50px",
                      // textAlign: "center",
                      border: "1px solid black",
                      //   padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        backgroundColor: "#f36e21",
                        // marginTop: "30px",
                        padding: "20px",
                        textAlign: "center",

                        // Center the element horizontally
                      }}
                    >
                      <p>Give Information Below</p>
                    </div>

                    <Grid container>
                      <Grid item lg={4}>
                        <div style={{ margin: "10px" }}>
                          <div>
                            <label
                              for="countries"
                              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Select Property type
                            </label>
                            <select
                              id="propertyType"
                              name="propertyType"
                              value={formData.propertyType}
                              required
                              onChange={handleChange}
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option value="residential">Residential</option>
                              <option value="commercial">Commercial</option>
                            </select>
                          </div>
                          <div
                            style={{ marginTop: "10px" }}
                            class="flex items-center mb-4"
                          >
                            <input
                              type="radio"
                              id="small"
                              name="properitySize"
                              value="small"
                              checked={formData.properitySize === "small"}
                              onChange={handleChange}
                              // name="default-radio"
                              required
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="default-radio-1"
                              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              Small
                            </label>
                          </div>
                          <div class="flex items-center">
                            <input
                              // checked
                              // id="default-radio-2"
                              type="radio"
                              id="large"
                              name="properitySize"
                              value="large"
                              checked={formData.properitySize === "large"}
                              onChange={handleChange}
                              // name="default-radio"
                              required
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="default-radio-2"
                              class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              Large
                            </label>
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={4}>
                        <div style={{ margin: "10px" }}>
                          <div lass="max-w-xs mx-auto">
                            <label
                              for="quantity-input"
                              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Input Number of buildings:
                            </label>
                            <div class="relative flex items-center max-w-[8rem]">
                              <input
                                // type="text"
                                type="number"
                                id="buildings"
                                name="buildings"
                                min={
                                  formData?.properitySize === "small" ? 1 : 1
                                }
                                max={
                                  formData?.properitySize === "small" ? 1 : 20
                                }
                                // max={20}
                                value={formData.buildings}
                                onChange={handleChange}
                                // id="quantity-input"
                                data-input-counter
                                aria-describedby="helper-text-explanation"
                                class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="999"
                                required
                              />
                            </div>
                            <div style={{ display: "flex", marginTop: "20px" }}>
                              <input
                                type="radio"
                                id="aux"
                                name="auxOrMain"
                                value="aux"
                                checked={formData.auxOrMain === "aux"}
                                style={{ marginRight: "10px" }}
                                onChange={handleChange}
                                // name="default-radio"
                                required
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                for="default-radio-1"
                                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                Include AUX
                              </label>

                              <input
                                type="radio"
                                id="mainbuilding"
                                name="auxOrMain"
                                value="mainbuilding"
                                checked={formData.auxOrMain === "mainbuilding"}
                                style={{
                                  marginLeft: "40px",
                                  marginRight: "10px",
                                }}
                                onChange={handleChange}
                                required
                                // name="default-radio"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                for="default-radio-1"
                                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                Main Building
                              </label>
                            </div>

                            {/* <p
                  id="helper-text-explanation"
                  class="mt-2 text-sm text-gray-500 dark:text-gray-400"
                >
                  Please select a 5 digit number from 0 to 9.
                </p> */}
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <label
                              for="message"
                              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Client Note:
                            </label>
                            <textarea
                              // id="message"
                              id="description"
                              name="description"
                              value={formData.description}
                              onChange={handleChange}
                              rows="4"
                              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Please Describe your project (Optional)"
                            ></textarea>
                          </div>
                        </div>
                      </Grid>
                      <Grid item lg={4}>
                        <Grid container>
                          <Grid item lg={12}>
                            <p style={{ margin: "10px" }}>Reports:</p>
                          </Grid>

                          <Grid item lg={6}>
                            <div style={{ margin: "5px" }}>
                              <div class="flex">
                                <input
                                  // type="checkbox"
                                  type="checkbox"
                                  id="roof"
                                  name="MATRoof"
                                  checked={formData.MATRoof}
                                  onChange={handleChange}
                                  class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                  // id="hs-checkbox-group-6"
                                />
                                <label
                                  for="hs-checkbox-group-6"
                                  class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                                >
                                  MATRoof
                                </label>
                              </div>

                              <div class="flex">
                                <input
                                  // type="checkbox"
                                  type="checkbox"
                                  id="roofx"
                                  disabled={
                                    formData?.propertyType === "commercial"
                                      ? true
                                      : false
                                  }
                                  name="MATRoofα"
                                  checked={formData.MATRoofα}
                                  onChange={handleChange}
                                  class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                  // id="hs-checkbox-group-4"
                                />
                                <label
                                  for="hs-checkbox-group-4"
                                  class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                                >
                                  MATRoofα
                                </label>
                              </div>

                              <div class="flex">
                                <input
                                  // type="checkbox"
                                  type="checkbox"
                                  id="sidex"
                                  name="MATSideX"
                                  checked={formData.MATSideX}
                                  onChange={handleChange}
                                  class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                  // id="hs-checkbox-group-6"
                                />
                                <label
                                  for="hs-checkbox-group-6"
                                  class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                                >
                                  MATSideX
                                </label>
                              </div>
                            </div>
                          </Grid>
                          <Grid item lg={6}>
                            <div style={{ margin: "5px" }}>
                              <div class="flex">
                                <input
                                  // type="checkbox"
                                  type="checkbox"
                                  id="painter"
                                  name="MATPainter"
                                  checked={formData.MATPainter}
                                  onChange={handleChange}
                                  class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                  // id="hs-checkbox-group-4"
                                />
                                <label
                                  for="hs-checkbox-group-4"
                                  class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                                >
                                  MATPainter
                                </label>
                              </div>

                              <div class="flex">
                                <input
                                  // type="checkbox"
                                  type="checkbox"
                                  id="painter"
                                  name="MATGutter"
                                  checked={formData.MATGutter}
                                  onChange={handleChange}
                                  class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                  // id="hs-checkbox-group-6"
                                />
                                <label
                                  for="hs-checkbox-group-6"
                                  class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                                >
                                  MATGutter
                                </label>
                              </div>
                              <div class="flex">
                                <input
                                  // type="checkbox"
                                  type="checkbox"
                                  id="concrete"
                                  name="MATConcrete"
                                  checked={formData.MATConcrete}
                                  onChange={handleChange}
                                  class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                  // id="hs-checkbox-group-4"
                                />
                                <label
                                  for="hs-checkbox-group-4"
                                  class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                                >
                                  MATConcrete
                                </label>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            style={{ marginTop: "30px", marginLeft: "20px" }}
                            item
                            lg={12}
                          >
                            {errorMessage && (
                              <p style={{ color: "red" }}>{errorMessage}</p>
                            )}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label className="loc-btn">
                                <span>Attach SiteMAP</span>
                                <input
                                  type="file"
                                  accept=".pdf, .doc, .docx"
                                  className="hidden"
                                  onChange={handleFileChange}
                                />
                              </label>
                              {siteMap && (
                                <p
                                  style={{ fontSize: "13px" }}
                                >{`${siteMap?.name.substring(0, 4)}.. ${
                                  siteMap?.name.includes(".")
                                    ? `.${siteMap?.name.split(".").pop()}`
                                    : ""
                                }`}</p>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>

                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ margin: "20px" }}
                  >
                    Add to Cart
                  </Button>
                </form>

                {/* <Modal isOpen={isModalOpen} onClose={closeModal} /> */}
              </div>
            </>
          )}
        </Card>
        {/* {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                <ArrowBackIcon />
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              {activeStep === steps.length - 1 ? (
                <ArrowForwardIcon style={{ opacity: 0.4 }} />
              ) : (
                <>
                  <Button onClick={handleNext}>
                    <ArrowForwardIcon color="black" />
                  </Button>
                </>
              )}
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? " gg " : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )} */}
      </Box>

      <Footer />
    </>
  );
}
