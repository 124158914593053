import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
// import Modal from "./PriceModel";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateFormData } from "../../slices/formSlice";
import { toast } from "react-toastify";

export default function Form({ location, file }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(()=>{}, [location]);
  useEffect(()=>{}, [file]);
  const [isModalOpen, setModalOpen] = useState(false);
  // const location = loc;
  // console.log(loc);
  const [formData, setFormData] = useState({
    location: {},
    propertyType: "residential",
    properitySize: "",
    buildings: 1,
    auxOrMain: "",
    description: "",
    MATGutter: false,
    MATRoofα: false,
    MATSideX: false,
    MATRoof: false,
    MATPainter: false,
    MATConcrete: false,
    bluePrint: null,
    sitemap: null,
  });
  const [siteMap, setSiteMap] = useState(null);
  const handleFileChange = (event) => {
    const sitemap = event.target.files[0];

    if (sitemap) {
      setSiteMap(sitemap);
      toast.success("SiteMap Uploaded");
    } else {
      setSiteMap(null);
      toast.error("error whileulpoading site map");
    }
  };

  // console.log("Im testing", testing);
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const [errorMessage, setErrorMessage] = useState("");

  const isAtLeastOneCheckboxChecked = () => {
    return Object.values(formData).some((value) => value === true);
  };

  console.log("Im file from Form", file);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isAtLeastOneCheckboxChecked()) {
      // alert("Please select at least one checkbox.");
      setErrorMessage("Please select at least one Service.");
      return;
    }
    if (file === null && Object.keys(location).length === 0) {
      alert("please select at least Location or BluePrint");
    } else {
      const testing = {
        ...formData,
        location: location,
        bluePrint: file,
        sitemap: siteMap,
      };
      dispatch(updateFormData(testing));
      navigate("/cart");
      console.log("im from form", testing);
      setErrorMessage("");
      console.log(errorMessage);
    }
  };

  return (
    <div style={{ maxWidth: "1100px", margin: "0 auto", zIndex: 100 }}>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            // width: "90%", // Adjust the width as per your preference
            margin: "0 auto",
            marginTop: "50px",
            // textAlign: "center",
            border: "1px solid black",
            //   padding: "20px",
          }}
        >
          <div
            style={{
              color: "white",
              backgroundColor: "#f36e21",
              // marginTop: "30px",
              padding: "20px",
              textAlign: "center",

              // Center the element horizontally
            }}
          >
            <p>Give Information Below</p>
          </div>

          <Grid container>
            <Grid item lg={4}>
              <div style={{ margin: "10px" }}>
                <div>
                  <label
                    for="countries"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Property type
                  </label>
                  <select
                    id="propertyType"
                    name="propertyType"
                    value={formData.propertyType}
                    required
                    onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="residential">Residential</option>
                    <option value="commercial">Commercial</option>
                  </select>
                </div>
                <div
                  style={{ marginTop: "10px" }}
                  class="flex items-center mb-4"
                >
                  <input
                    type="radio"
                    id="small"
                    name="properitySize"
                    value="small"
                    checked={formData.properitySize === "small"}
                    onChange={handleChange}
                    // name="default-radio"
                    required
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-radio-1"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Small
                  </label>
                </div>
                <div class="flex items-center">
                  <input
                    // checked
                    // id="default-radio-2"
                    type="radio"
                    id="large"
                    name="properitySize"
                    value="large"
                    checked={formData.properitySize === "large"}
                    onChange={handleChange}
                    // name="default-radio"
                    required
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-radio-2"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Large
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item lg={4}>
              <div style={{ margin: "10px" }}>
                <div lass="max-w-xs mx-auto">
                  <label
                    for="quantity-input"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Input Number of buildings:
                  </label>
                  <div class="relative flex items-center max-w-[8rem]">
                    {/* <button
                    type="button"
                    id="decrement-button"
                    data-input-counter-decrement="quantity-input"
                    class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  >
                    <svg
                      class="w-3 h-3 text-gray-900 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 2"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 1h16"
                      />
                    </svg>
                  </button> */}
                    <input
                      // type="text"
                      type="number"
                      id="buildings"
                      name="buildings"
                      min={formData?.properitySize === "small" ? 1 : 1}
                      max={formData?.properitySize === "small" ? 1 : 20}
                      // max={20}
                      value={formData.buildings}
                      onChange={handleChange}
                      // id="quantity-input"
                      data-input-counter
                      aria-describedby="helper-text-explanation"
                      class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="999"
                      required
                    />
                    {/* <button
                    type="button"
                    id="increment-button"
                    data-input-counter-increment="quantity-input"
                    class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                  >
                    <svg
                      class="w-3 h-3 text-gray-900 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 1v16M1 9h16"
                      />
                    </svg>
                  </button> */}
                  </div>
                  <div style={{ display: "flex", marginTop: "20px" }}>
                    <input
                      type="radio"
                      id="aux"
                      name="auxOrMain"
                      value="aux"
                      checked={formData.auxOrMain === "aux"}
                      style={{ marginRight: "10px" }}
                      onChange={handleChange}
                      // name="default-radio"
                      required
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-1"
                      class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Include AUX
                    </label>

                    <input
                      type="radio"
                      id="mainbuilding"
                      name="auxOrMain"
                      value="mainbuilding"
                      checked={formData.auxOrMain === "mainbuilding"}
                      style={{ marginLeft: "40px", marginRight: "10px" }}
                      onChange={handleChange}
                      required
                      // name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-1"
                      class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Main Building
                    </label>
                  </div>

                  {/* <p
                  id="helper-text-explanation"
                  class="mt-2 text-sm text-gray-500 dark:text-gray-400"
                >
                  Please select a 5 digit number from 0 to 9.
                </p> */}
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    for="message"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Client Note:
                  </label>
                  <textarea
                    // id="message"
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Please Describe your project (Optional)"
                  ></textarea>
                </div>
              </div>
            </Grid>
            <Grid item lg={4}>
              <Grid container>
                <Grid item lg={12}>
                  <p style={{ margin: "10px" }}>Reports:</p>
                </Grid>

                <Grid item lg={6}>
                  <div style={{ margin: "5px" }}>
                    <div class="flex">
                      <input
                        // type="checkbox"
                        type="checkbox"
                        id="roof"
                        name="MATRoof"
                        checked={formData.MATRoof}
                        onChange={handleChange}
                        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        // id="hs-checkbox-group-6"
                      />
                      <label
                        for="hs-checkbox-group-6"
                        class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                      >
                        MATRoof
                      </label>
                    </div>

                    <div class="flex">
                      <input
                        // type="checkbox"
                        type="checkbox"
                        id="roofx"
                        disabled={
                          formData?.propertyType === "commercial" ? true : false
                        }
                        name="MATRoofα"
                        checked={formData.MATRoofα}
                        onChange={handleChange}
                        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        // id="hs-checkbox-group-4"
                      />
                      <label
                        for="hs-checkbox-group-4"
                        class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                      >
                        MATRoofα
                      </label>
                    </div>

                    <div class="flex">
                      <input
                        // type="checkbox"
                        type="checkbox"
                        id="sidex"
                        name="MATSideX"
                        checked={formData.MATSideX}
                        onChange={handleChange}
                        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        // id="hs-checkbox-group-6"
                      />
                      <label
                        for="hs-checkbox-group-6"
                        class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                      >
                        MATSideX
                      </label>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={6}>
                  <div style={{ margin: "5px" }}>
                    <div class="flex">
                      <input
                        // type="checkbox"
                        type="checkbox"
                        id="painter"
                        name="MATPainter"
                        checked={formData.MATPainter}
                        onChange={handleChange}
                        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        // id="hs-checkbox-group-4"
                      />
                      <label
                        for="hs-checkbox-group-4"
                        class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                      >
                        MATPainter
                      </label>
                    </div>

                    <div class="flex">
                      <input
                        // type="checkbox"
                        type="checkbox"
                        id="painter"
                        name="MATGutter"
                        checked={formData.MATGutter}
                        onChange={handleChange}
                        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        // id="hs-checkbox-group-6"
                      />
                      <label
                        for="hs-checkbox-group-6"
                        class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                      >
                        MATGutter
                      </label>
                    </div>
                    <div class="flex">
                      <input
                        // type="checkbox"
                        type="checkbox"
                        id="concrete"
                        name="MATConcrete"
                        checked={formData.MATConcrete}
                        onChange={handleChange}
                        class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        // id="hs-checkbox-group-4"
                      />
                      <label
                        for="hs-checkbox-group-4"
                        class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                      >
                        MATConcrete
                      </label>
                    </div>
                  </div>
                </Grid>
                <Grid
                  style={{ marginTop: "30px", marginLeft: "20px" }}
                  item
                  lg={12}
                >
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label className="loc-btn">
                      <span>Attach SiteMAP</span>
                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                    {siteMap && (
                      <p
                        style={{ fontSize: "13px" }}
                      >{`${siteMap?.name.substring(0, 4)}.. ${
                        siteMap?.name.includes(".")
                          ? `.${siteMap?.name.split(".").pop()}`
                          : ""
                      }`}</p>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Button type="submit" variant="contained" sx={{ margin: "20px" }}>
          Add to Cart
        </Button>
      </form>

      {/* <Modal isOpen={isModalOpen} onClose={closeModal} /> */}
    </div>
  );
}
