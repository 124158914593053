import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Bar from "../components/header/Bar";
import NavBar from "../components/navigation/NavBar";
import Footer from "../components/navigation/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ScrollToTopOnRefresh from "../components/ScrollToTopOnRefresh";
import axios from "axios";
import BASE_URL from "../services/BASE_URL";

export default function ForgotPassword() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "home > Login > ForgotPassword";
  const [email, setEmail] = useState("");
  //   const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);


  const submitHandler = async (e) => {
    e.preventDefault();
    if (load) return; // Prevent multiple clicks while submitting
    setLoad(true); // Set loading state to true
    
    try {
      const res = await axios.post(`${BASE_URL}/api/users/forgot-password`, { email });
      if (res.data.Status === "Success") {
        navigate('/login');
        toast.success('Check your email')
      }
      else {
        toast.error('Email not found. Please check and try again.');
      }
    } catch (err) {
      console.log(err);
      toast.error('Please try later');
    } finally {
      setLoad(false); // Reset loading state regardless of success or failure
    }
  };
  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   setLoad(true);
  //   axios.post(`${BASE_URL}/api/users/forgot-password`, { email }).then((res) => {
  //     if (res.data.Status === "Success") {
  //       navigate('/login')
  //     }
  //   }).catch(err =>{
  //       console.log(err)
  //   });
  // };

  return (
    <>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>
            Forgot Password
          </h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      <Grid container marginBottom={"50px"}>
        <Grid margin={"0 auto"} item sm={12} md={12} lg={12}>
          <section
            style={{
              backgroundColor: "#f36e21",
              //   padding: "10px",
              margin: "0 auto",
              borderRadius: "20px",
              marginTop: "50px",
              maxWidth: "500px",

              // paddingBottom:'10px',
            }}
            className="bg-white dark:bg-gray-900"
          >
            <div class="py-8 lg:py-6 px-4 mx-auto max-w-screen-md">
              <div
                style={{
                  border: "1px solid white",
                  width: "inherit",
                  maxHeight: "90px",
                  // borderImage: "linear-gradient(to right, green, black)",
                  // borderImageSlice: 1,
                  boxSizing: "border-box",
                }}
              >
                <h2 class="mt-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                  Forgot Password
                </h2>

                <p
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#f36e21",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                  class="font-light text-center text-black-500 dark:text-gray-400 sm:text-xl"
                >
                  Click here for{" "}
                  <Link to="/login">
                    <span
                      style={{ color: "white", textDecoration: "underline" }}
                    >
                      Login
                    </span>
                  </Link>
                </p>
              </div>
              <form onSubmit={submitHandler} className="space-y-3 p-10">
                <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Email*
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="email"
                    // placeholder='Enter email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="name@gmail.com"
                    required
                  />
                </div>

                {/* <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    // className=""
                  >
                    Your email(required)
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="email"
                    id="email"
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="name@flowbite.com"
                    required
                  />
                </div> */}

                {/* <div class="sm:col-span-2">
                <label
                  for="message"
                  className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  // className=""
                >
                  Your message
                </label>
                <textarea
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    padding: "15px",
                    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.5)",
                  }}
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Leave a comment..."
                ></textarea>
              </div> */}
                <div style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    disabled={load}
                    style={{
                      backgroundColor: "grey",
                      borderRadius: "30px",
                      marginTop: "20px",
                    }}
                    class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </section>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
