import React, { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import pic from "../../assets/Fuc-Facts-Image.jpg";
import Countup from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./style.css";

export default function FunFact() {
  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   const targetCount = 100;
  //   const duration = 3000; // Animation duration in milliseconds
  //   const interval = 50; // Interval for updating the count

  //   const steps = Math.ceil(duration / interval);
  //   const stepValue = targetCount / steps;

  //   let currentStep = 0;
  //   const intervalId = setInterval(() => {
  //     setCount((prevCount) => {
  //       currentStep++;
  //       const nextCount = prevCount + stepValue;

  //       if (currentStep >= steps) {
  //         clearInterval(intervalId);
  //         return targetCount;
  //       }

  //       return nextCount;
  //     });
  //   }, interval);

  //   return () => clearInterval(intervalId);
  // }, []);
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className="funfact-cont">
        <Grid container mt={10}>
          <Grid item md={12} lg={6}>
            <div className="overlay-funfact">
              <div style={{ padding: 30 }}>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: "25px",
                    paddingLeft: 20,
                    // textAlign: "center",
                  }}
                >
                  What Sets Us Apart{" "}
                </h1>
                {/* <br /> */}
               
                <Grid
                  container
                  spacing={2}
                  maxWidth={"400px"}
                  margin={"0 auto"}
                >
                  <Grid
                    item
                    sm={4}
                    lg={6}
                    // sx={{ display: "flex", justifyContent: "center",  }}
                  >
                    <Card className="funfact-cards">
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {counterOn && (
                          <Countup start={0} end={100} duaration={3} delay={0} />
                        )}
                        +
                      </p>{" "}
                      <p style={{ fontWeight: "bold" }}>Satisfied <br /> Customers</p>
                      {/* <p>Lorem, ipsum dolor sit</p> */}
                    </Card>
                  </Grid>

                  <Grid
                    item
                    sm={4}
                    lg={6}
                    // sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card className="funfact-cards">
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {counterOn && (
                          <Countup start={0} end={97} duaration={3} delay={0} />
                        )}
                        %
                      </p>{" "}
                      <p cla style={{ fontWeight: "bold" }}>
                        Accuracy <br /> Guaranted
                      </p>
                      {/* <p>Lorem, ipsum dolor sit</p> */}
                    </Card>
                  </Grid>

                  <Grid
                    item
                    sm={4}
                    lg={6}
                    // sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card className="funfact-cards">
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {counterOn && (
                          <Countup start={0} end={2} duaration={4} delay={0} />
                        )}
                        hrs
                      </p>{" "}
                      <p style={{ fontWeight: "bold" }}>Delivery <br />Time</p>
                      {/* <p>Lorem, ipsum dolor sit</p> */}
                    </Card>
                  </Grid>

                  <Grid
                    item
                    sm={4}
                    lg={6}
                    // sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card className="funfact-cards">
                      <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {counterOn && (
                          <Countup
                            start={0}
                            end={2}
                            duaration={3}
                            delay={0}
                          />
                        )}
                        k+
                      </p>{" "}
                      <p style={{ fontWeight: "bold" }}>Completed <br /> Order</p>
                      {/* <p>Lorem, ipsum dolor sit</p> */}
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>

          <Grid item lg={6}>
            <img className="lady-image" src={pic} alt="" />
          </Grid>
        </Grid>
      </div>
    </ScrollTrigger>
  );
}
