import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Bar from "../header/Bar";
import Footer from "../navigation/Footer";
import NavBar from "../navigation/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { selectFormData } from "../../slices/formSlice";
import { selectImage } from "../../slices/locImageSlice";
import { selectTotalPrice } from "../../slices/totalPriceSlice";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../slices/usersApiSlice";
import { setCredentials } from "../../slices/authSlice";
import { selectFiles } from "../../slices/filesSlice";

export default function LoginNPayment() {
  const { userInfo } = useSelector((state) => state.auth);
  const screenshot = useSelector(selectImage);
  const formData = useSelector(selectFormData);
  const totalPrice = useSelector(selectTotalPrice);
  console.log("Im from cart", formData);
  const a = "home > cart";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  var total_Price = parseFloat(totalPrice);
  const [login, { isLoading }] = useLoginMutation();
  const files = useSelector(selectFiles);
  // console.log("files mil gyi", files);

  // const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/cart");
    }
  }, [navigate, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
      toast.success("Login Successfully");
      navigate("/cart");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
      // toast.success({err?.data?.message})
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Bar />
      <NavBar />

      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto", color: "white" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Cart</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      <div
        style={{ maxWidth: "1200px", overflow: "auto" }}
        className="container mx-auto mt-10"
      >
        <div className="flex shadow-md my-10">
          <div className="w-3/4 bg-white px-10 py-10">
            <div className="flex justify-between border-b pb-8">
              <h1 className="font-semibold text-2xl">To continue</h1>
              <h2 className="font-semibold text-2xl">
                {/* {trueProperties?.length} */}
                Login
              </h2>
            </div>
            <form
              onSubmit={submitHandler}
              style={{ margin: "0 auto", marginTop: "20px" }}
              className="w-full max-w-lg"
            >
              {/* Contact number and company name */}
              {/* <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    First Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="John"
                  />
                 
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Last Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="Doe"
                  />
                </div>
              </div> */}

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    Email*
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-password"
                    type="gmail"
                    placeholder="johndoe@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {/* <p className="text-gray-600 text-xs italic">
                    Make it stronger
                  </p> */}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    Password*
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-password"
                    type="password"
                    placeholder="******************"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {/* <p className="text-gray-600 text-xs italic">
                    Make it stronger
                  </p> */}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <button
                    className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-password"
                    style={{
                      backgroundColor: "green",
                      fontWeight: "bold",
                      color: "white",
                    }}
                    type="submit"
                    // type="button"
                  >
                    Login
                  </button>
                </div>
              </div>
              <p
                style={{
                  marginTop: "20px",
                  // backgroundColor: "#f36e21",
                  marginLeft: "10%",
                  marginRight: "10%",
                }}
                class="font-light text-center text-black-500 dark:text-gray-400 sm:text-xl"
              >
                Have an account?{" "}
                <Link to="/register&payment">
                  <span style={{ color: "blue", textDecoration: "underline" }}>
                    Signup
                  </span>
                </Link>
              </p>
            </form>
            {/* <a
              href="#"
              className="flex font-semibold text-indigo-600 text-sm mt-10"
            >
              <svg
                className="fill-current mr-2 text-indigo-600 w-4"
                viewBox="0 0 448 512"
              >
                <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
              </svg>
              Continue Shopping
            </a> */}
          </div>

          <div id="summary" className="w-1/4 px-8 py-10">
            <h1 className="font-semibold text-2xl border-b pb-8">
              Order Summary
            </h1>
            <div className="flex justify-between mt-10 mb-5">
              <span className="font-semibold text-sm uppercase">
                Items
                {/* {trueProperties?.length} */}
              </span>
              <span className="font-semibold text-sm">
                ${total_Price?.toFixed(2)}
              </span>
            </div>
            {/* <div>
              <label className="font-medium inline-block mb-3 text-sm uppercase">
                Shipping
              </label>
              <select className="block p-2 text-gray-600 w-full text-sm">
                <option>Standard shipping - $10.00</option>
              </select>
            </div> */}
            <div className="py-10">
              {files?.bluePrint != null ? (
                <>
                  <span className="font-semibold text-sm uppercase">
                    Blue Print: {files?.bluePrint}
                  </span>
                </>
              ) : (
                <>
                  <img src={screenshot} alt="abc" />
                </>
              )}
            </div>
            {/* <button className="bg-red-500 hover:bg-red-600 px-5 py-2 text-sm text-white uppercase">
              Apply
            </button> */}
            <div className="border-t mt-8">
              <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                <span>Total cost</span>
                <span>${total_Price?.toFixed(2)}</span>
              </div>
              {/* {userInfo ? (
                <>
                  {" "}
                  <Link to="/payment">
                    <button className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">
                      Checkout
                    </button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/register&payment">
                    <button className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">
                      Checkout
                    </button>
                  </Link>
                </>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
