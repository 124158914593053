import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AboutUs from "../components/Intro/Intro";
import Baner from "../components/Banner/Baner";
import Services from "../components/Services/Services";
// import Services from "./components/Services/Services";
import Slider from "../components/Slider/Slider";
import ProductCatalog from "../components/productCatalog/ProductCatalog";
import { Element } from "react-scroll";
import Form from "../components/InfoForm/Form";
import Test from "../components/InfoForm/Test";
import Footer from "../components/navigation/Footer";
import NavBar from "../components/navigation/NavBar";
import Bar from "../components/header/Bar";
import CatalogHeader from "../components/productCatalog/CatalogHeader";
import ScrollToTopOnRefresh from "../components/ScrollToTopOnRefresh";
// import Slider2 from "../components/Slider/Slider2/Slider2";

export default function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Reliable Material Estimation for Your Roof, Siding, Paint, Gutter and
          Concrete | MatEstimates
        </title>
        <meta
          name="description"
          content="MatEstimates provides accurate and reliable material estimation for a variety of home improvement projects, including roofs, paint, concrete, and siding. With years of experience."
        />
      </Helmet>
      <ScrollToTopOnRefresh />
      <Bar />
      <NavBar />

      {/* <div style={{ maxWidth: "1200px", width:'auto' }}> */}

      <Element name="section2" className="element">
        <Baner />
      </Element>

      <AboutUs />
      <CatalogHeader />
      <ProductCatalog />
      <Services />
      <Slider />
      {/* <Slider2 /> */}
      {/* <Footer /> */}
      {/* </div> */}
      <Footer />
    </>
  );
}
