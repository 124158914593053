// src/redux/totalPriceSlice.js
import { createSlice } from '@reduxjs/toolkit';

const totalPriceSlice = createSlice({
  name: 'totalPrice',
  initialState: '',
  reducers: {
    setTotalPrice: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTotalPrice } = totalPriceSlice.actions;
export const selectTotalPrice = (state) => state.totalPrice;
export default totalPriceSlice.reducer;
