import React, { useEffect } from "react";
import NavBar from "../navigation/NavBar";
import Bar from "../header/Bar";
import Footer from "../navigation/Footer";
import "../globalStyles/global.css";
import ScrollToTopOnRefresh from "../ScrollToTopOnRefresh";

export default function PrivacyPolicy() {
  const a = "home > PrivacyPolicy";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>
            Privacy Poilcy
          </h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>
      <div style={styles.container}>
        <h1
          style={{ textAlign: "center", alignItems: "center" }}
          className="heading1"
        >
          Privacy Policy
        </h1>

        <div style={{ textAlign: "justify" }}>
          <h2 className="heading3">1. Information Collection:</h2>
          <p>
            At MAT Estimates, we collect various types of information to enhance
            and personalize your experience with our services. The information
            we gather includes:
          </p>

          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontWeight: "bold" }}>Personal Information:</span>{" "}
              This may include your name, contact details, and other
              identifiable data necessary for account creation and service
              delivery.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>
                Non-Personal Information:
              </span>{" "}
              We collect non-personal information that does not directly
              identify you, such as device information, browser type, and usage
              data, to improve our services and user experience.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Sensitive Information:</span>{" "}
              We prioritize the protection of sensitive data. Any sensitive
              information, such as financial details, is handled securely and in
              compliance with relevant regulations.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Payment Data:</span> For
              transactions, we collect and process payment data securely,
              ensuring a safe and efficient payment process through secured
              third-party payment processors.
            </li>
          </ul>
        </div>
        {/* second */}
        <div style={{ textAlign: "justify" }}>
          <h2 className="heading3">2. Processing Your Information:</h2>
          <p>
            For transactions, we collect and process payment data securely,
            ensuring a safe and efficient payment process through secured
            third-party payment processors.
          </p>
          <ul style={{ listStyleType: "disc" }}>
            <li>Facilitating account creation</li>
            <li>Delivering and managing our services</li>
            <li>Responding to user queries</li>
            <li>Fulfilling and managing orders</li>
          </ul>
        </div>

        {/* third */}
        <div style={{ textAlign: "justify" }}>
          <h2 className="heading3">3. Sharing Information:</h2>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontWeight: "bold" }}>Business Transfer:</span> We
              may share or transfer your information during negotiations,
              mergers, sales of company assets, financing, or acquisition of all
              or part of our business.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>
                Google Map Platform APIs:
              </span>{" "}
              : When using certain Google Maps Platform APIs (e.g., Google Maps
              API, Places API), we may share your information. This involves
              obtaining and storing your location in your device cache. You can
              revoke your consent at any time by contacting us through the
              details provided on our website.
            </li>
          </ul>
        </div>
        {/* fourth */}
        <div style={{ textAlign: "justify" }}>
          <h2 className="heading3">4. Cookies:</h2>
          <p>
            Cookies play a vital role in providing a seamless and personalized
            experience on our website. Our Cookie Policy outlines how we use
            cookies and similar technologies to enhance the user experience,
            track usage patterns, and improve our services.
          </p>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontWeight: "bold" }}>Cookie Policy: </span> Our
              website uses cookies to enhance your browsing experience. Cookies
              are small text files stored on your device that help us analyze
              usage patterns, improve functionality, and personalize content. By
              using our website, you consent to the use of cookies as described
              in our Cookie Policy. For further details or inquiries about our
              privacy practices, please contact us using the provided contact
              details on our website. We are committed to protecting your
              privacy and ensuring the secure handling of your information.
            </li>
          </ul>
        </div>
        <div style={{ textAlign: "justify" }}>
          <p>
            <span style={{ fontWeight: "bold" }}>Your Privacy Matters: </span>
            MAT Estimates is dedicated to maintaining the privacy and security
            of your personal information. Our Residents Privacy Notice for USA
            residents outlines the collection of personal and non-personal
            information, including names, addresses, and device data,
            emphasizing its purpose for service provision and improvement. We
            may share data for specific reasons like business transfers or legal
            requirements. Utilizing cookies and tracking technologies is
            explained in our Cookie Policy, with a focus on user security
            through encryption and access controls. Users have rights to access,
            correct, or delete their information, and data retention details are
            provided. We address children's privacy and encourage periodic
            review of our Privacy Policy, which may be updated. For inquiries,
            we offer contact information, ensuring compliance with privacy laws
            and regulations.
          </p>
        </div>
        {/* second heading */}
        <div style={{ textAlign: "justify" }}>
          <h2 className="heading2">
            Privacy Notice for Residents of California, Nevada, Utah, Colorado,
            and Connecticut:
          </h2>
        </div>
        {/* first */}
        <div style={{ textAlign: "justify" }}>
          <h2 className="heading3">1. California Residents:</h2>

          <ul style={{ listStyleType: "disc" }}>
            <li>
              <span style={{ fontWeight: "bold" }}>Right to Know:</span> You
              have the right to request information about the categories of
              personal information we have collected, the sources from which we
              collected it, the purposes for which it was collected, and the
              third parties with whom we share it.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Right to Delete:</span>
              You have the right to request the deletion of your personal
              information collected or maintained by us.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>Right to Opt-Out: </span>
              You have the right to opt-out of the sale of your personal
              information. We do not sell personal information, but we respect
              your right to opt-out. To exercise these rights, please contact us
              through the details provided on our website.
            </li>
          </ul>
        </div>

        {/* second */}
        <div style={{ textAlign: "justify" }}>
          <h2 className="heading3">2. Nevada Residents:</h2>

          <ul style={{ listStyleType: "disc" }}>
            <li>
              Nevada residents have the right to opt-out of the sale of their
              personal information. While we do not sell personal information,
              we respect your right to opt-out. To exercise this right, please
              contact us using the provided contact details on our website.
            </li>
          </ul>
        </div>

        {/* Third */}
        <div style={{ textAlign: "justify" }}>
          <h2 className="heading3">
            3. Utah, Colorado, Connecticut Residents:
          </h2>

          <ul style={{ listStyleType: "disc" }}>
            <li>
              Residents of Utah, Colorado, and Connecticut may have additional
              privacy rights under state laws. We are committed to complying
              with these state-specific regulations and ensuring the protection
              of your privacy.
            </li>
          </ul>
        </div>
        <p style={{ marginTop: "25px", textAlign: "justify" }}>
          For any questions or concerns related to your privacy rights or the
          information outlined in this notice, please reach out to us through
          the contact details provided on our website.
        </p>
        {/* Add more sections as needed */}
      </div>
      <Footer />
    </>
  );
}

const styles = {
  container: {
    maxWidth: "1100px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
};
