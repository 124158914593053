import React, { useEffect } from "react";

import Bar from "../components/header/Bar";
import NavBar from "../components/navigation/NavBar";
import Footer from "../components/navigation/Footer";
import ScrollToTopOnRefresh from "../components/ScrollToTopOnRefresh";

export default function Terms() {
  const data = [
    {
      heading: "What payment methods do you accept?",
      content:
        "We accept payments via Visa, MasterCard, and American Express. Our secure payment gateway ensures a hassle-free transaction experience.",
    },
    {
      heading: "What is the error rate in your services?",
      content:
        "Our services boast a minimal error rate, maintaining an impressive 97% accuracy. We are committed to delivering precise and reliable results.",
    },
    {
      heading: "What information is required to place an order?",
      content:
        "To place an order, simply enter your address, confirm the location details, and provide a brief description of your required building. Alternatively, you can expedite the process by uploading a blueprint. Our streamlined order process ensures efficiency and convenience.",
    },
    {
      heading: "What details are included in the report?",
      content:
        "Our comprehensive reports include vital information such as pitch, material quantity, roof area in square feet, dimension and a waste factor analysis. We aim to provide you with all the necessary details for informed decision-making.",
    },
    {
      heading: "Is there a discount available for bulk orders?",
      content:
        "Yes, we offer discounts on bulk orders. For customized pricing and to discuss your specific requirements, please reach out to our dedicated sales team. We are here to ensure you receive the best value for your investment.",
    },
  ];

  const a = "home > Terms&Conditions";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>
            Terms&Conditions
          </h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      <div style={styles.container}>
        <h1
          style={{
            textAlign: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
          className="heading1"
        >
          Frequently Asked Questions (FAQ)
        </h1>
        {/* <p>
          This document represents the official "Terms of Use" or "User
          Agreement" between you and MAT Estimates. Before engaging with any of
          MAT Estimates' websites, participating in transactions, or utilizing
          any information provided by MAT Estimates, you explicitly acknowledge
          that you have thoroughly read and comprehended this agreement and are
          in full agreement with its terms. MAT Estimates, represented as "we"
          or "us" in this agreement, operates as a reputable entity in
          accordance with relevant legal standards. As the user of our
          website(s), you are referred to as "you." By accessing and utilizing
          any website operated by MAT Estimates, regardless of the method of
          access, you confirm and consent to abide by all the statements, terms,
          and conditions outlined in this agreement, collectively referred to as
          "provisions." Should there be any disagreement with any provision of
          this agreement, your authorization to use MAT Estimates’ websites,
          products, or services is nullified. In the event of dissatisfaction
          with the performance of our website, products, or services, your
          exclusive recourse is to cease usage.
        </p> */}

        {data.map((item, index) => (
          <div key={index}>
            <h4
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                marginTop: "10px",
              }}
              className="heading4"
            >
              {index + 1}. {item.heading}
            </h4>
            <p style={{ textAlign: "justify" }}>{item.content}</p>
          </div>
        ))}

        {/* Add more sections as needed */}
      </div>
      <Footer />
    </>
  );
}

const styles = {
  container: {
    maxWidth: "1100px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
};
