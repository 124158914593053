import React, { useEffect, useState } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import "./style.css";

const Accordion = ({ isOpen, title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setAccordionOpen(true);
    }
  }, [isOpen]);
  return (
    <div className="py-2">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="accordian-btn flex w-full"
        id={accordionOpen ? "open-id" : "closed-id"}
        // style={{ backgroundColor: accordionOpen ? '#f36e21' : '#EEF5FF'}}
      >
        <PlayCircleIcon
          style={{ color: accordionOpen ? "white" : "#f36e21" }}
          className="accordian-icon"
        />
        {/* <svg
          className="fill-indigo-500 shrink-0 ml-8"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
        </svg> */}
        <span className="accordian-title">{title}</span>
        {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div style={{maxWidth:'600px'}} className="overflow-hidden">
          {answer}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
