import "./HowWeDo.css";
export default function HowWeDo() {
  return (
    <>
      <div className="how-card-cont">
        <div className="how-left">
          <p className="left-p">
            <span
              style={{
                fontWeight: "bold",
                fontSize: "26px",
                marginBottom: "20px",
              }}
            >
              {" "}
              Who We Are
            </span>{" "}
            <br />
            MAT Estimates is a dedicated team of professionals committed to{" "}
            <br />
            simplifying the material estimation process for construction, <br />
            manufacturing, and various other industries. With years of expertise{" "}
            <br />
            in the field, we understand the critical role that accurate material{" "}
            <br />
            estimates play in the success of any project. Our goal is to <br />
            streamline and optimize this crucial aspect, ensuring that you have{" "}
            <br />
            the right materials in the right quantities at the right time <br />
          </p>
        </div>
        <div className="how-right">
          <p style={{ textAlign: "left", maxWidth: "600px" }}>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "26px",
                marginBottom: "20px",
              }}
            >
              What We DO
            </span>{" "}
            <br />
            At MAT Estimates, we don't just provide numbers; we provide the{" "}
            <br />
            foundation for your project's success. Explore our services and let
            us <br />
            be your partner in achieving accurate and reliable estimates for
            your <br />
            construction endeavors..
          </p>
        </div>
      </div>

      <div className="mob-cont">
        <div className="how-left">
          <p className="left-p">
            <span
              style={{
                fontWeight: "bold",
                fontSize: "26px",
                marginBottom: "20px",
              }}
            >
              {" "}
              Who We Are
            </span>{" "}
            <br />
            MAT Estimates is a dedicated team of professionals committed to
            simplifying the material estimation process for construction,
            manufacturing, and various other industries. With years of expertise{" "}
            in the field, we understand the critical role that accurate material{" "}
            estimates play in the success of any project. Our goal is to
            streamline and optimize this crucial aspect, ensuring that you have{" "}
            the right materials in the right quantities at the right time
          </p>
        </div>
        <div className="how-right">
          <p className="left-p">
            <span
              style={{
                fontWeight: "bold",
                fontSize: "26px",
                marginBottom: "20px",
              }}
            >
              {" "}
              What We Do
            </span>{" "}
            <br />
            <br />
            At MAT Estimates, we don't just provide numbers; we provide the{" "}
            foundation for your project's success. Explore our services and let
            us be your partner in achieving accurate and reliable estimates for
            your construction endeavors..
          </p>
        </div>
      </div>
    </>
  );
}
