import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Bar from "../components/header/Bar";
import NavBar from "../components/navigation/NavBar";
import Footer from "../components/navigation/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ScrollToTopOnRefresh from "../components/ScrollToTopOnRefresh";

export default function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "home > Login";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...res }));
      toast.success('Login Successfully')
      navigate("/");
      
    } catch (err) {
      toast.error(err?.data?.message || err.error);
      // toast.success({err?.data?.message})
    }
  };

  return (
    <>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Login</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      <Grid container marginBottom={'50px'}>
        <Grid margin={"0 auto"} item sm={12} md={12} lg={12}>
          <section
            style={{
              backgroundColor: "#f36e21",
              //   padding: "10px",
              margin: "0 auto",
              borderRadius: "20px",
              marginTop: "50px",
              maxWidth: "500px",

              // paddingBottom:'10px',
            }}
            className="bg-white dark:bg-gray-900"
          >
            <div class="py-8 lg:py-6 px-4 mx-auto max-w-screen-md">
              <div
                style={{
                  border: "1px solid white",
                  width: "inherit",
                  maxHeight: "90px",
                  // borderImage: "linear-gradient(to right, green, black)",
                  // borderImageSlice: 1,
                  boxSizing: "border-box",
                }}
              >
                <h2 class="mt-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                  Login
                </h2>

                <p
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#f36e21",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                  class="font-light text-center text-black-500 dark:text-gray-400 sm:text-xl"
                >
                  No account?{" "}
                  <Link to="/signup">
                    <span
                      style={{ color: "white", textDecoration: "underline" }}
                    >
                      signup
                    </span>
                  </Link>
                </p>
              </div>
              <form onSubmit={submitHandler} className="space-y-3 p-10">
                <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Email*
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="email"
                    // placeholder='Enter email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="name@gmail.com"
                    required
                  />
                </div>

                {/* <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    // className=""
                  >
                    Your email(required)
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="email"
                    id="email"
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="name@flowbite.com"
                    required
                  />
                </div> */}

                <div>
                  <label
                    for="subject"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    // className=""
                  >
                    Password*
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="contact-input block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    // placeholder="Password"
                    required
                  />
                </div>

              <div style={{ textAlign: "center",textDecoration:'underline', color:'white' }}>
                <Link to="/forgot-password">
                  Forgot Password
                </Link>
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    style={{ backgroundColor: "grey", borderRadius: "30px" }}
                    class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </section>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
