import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import Bar from "../header/Bar";
import Footer from "../navigation/Footer";
import NavBar from "../navigation/NavBar";
import { useSelector } from "react-redux";
import { selectFormData } from "../../slices/formSlice";
import { selectImage } from "../../slices/locImageSlice";
import { useDispatch } from "react-redux";
import { useRegisterMutation } from "../../slices/usersApiSlice";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { setCredentials } from "../../slices/authSlice";
import { selectTotalPrice } from "../../slices/totalPriceSlice";
import { selectFiles } from "../../slices/filesSlice";

export default function RegisterNPayment() {
  const { userInfo } = useSelector((state) => state.auth);
  const screenshot = useSelector(selectImage);
  const formData = useSelector(selectFormData);
  const totalPrice = useSelector(selectTotalPrice);
  console.log("Im from cart", formData);
  const a = "home > cart";
  // totalPrice = parseFloat(totalPrice);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  var total_Price = parseFloat(totalPrice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const files = useSelector(selectFiles);
  console.log("files mil gyi", files);

  const [register, { isLoading }] = useRegisterMutation();

  // const { userInfo } = useSelector((state) => stasette.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/cart");
    }
  }, [navigate, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        const res = await register({
          fname,
          lname,
          email,
          contact,
          company,
          password,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        toast.success("Register Successfully");
        navigate("/cart");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Bar />
      <NavBar />

      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto", color: "white" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Cart</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      <div
        style={{ maxWidth: "1200px", overflow: "auto" }}
        className="container mx-auto mt-10"
      >
        <div className="flex shadow-md my-10">
          <div className="w-3/4 bg-white px-10 py-10">
            <div className="flex justify-between border-b pb-8">
              <h1 className="font-semibold text-2xl">To continue</h1>
              <h2 className="font-semibold text-2xl">
                {/* {trueProperties?.length} */}
                Register
              </h2>
            </div>
            <form
              onSubmit={submitHandler}
              style={{ margin: "0 auto", marginTop: "20px" }}
              className="w-full max-w-lg"
            >
              {/* First Name Last Name  */}
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    First Name
                  </label>
                  <input
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="John"
                    required
                  />
                  {/* <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p> */}
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Last Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="Doe"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    required
                  />
                </div>
              </div>

              {/* Gmail Contact number */}
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    Gmail
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="gmail"
                    placeholder="john_doe@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {/* <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p> */}
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Contact No.
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="000 000 000 000"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    required
                  />
                </div>
              </div>

              {/* Contact number and company name */}
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    Company Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="John"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                  {/* <p className="text-red-500 text-xs italic">
                    Please fill out this field.
                  </p> */}
                </div>
                {/* <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Last Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="Doe"
                  />
                </div> */}
              </div>

              {/* Contact number and company name */}
              {/* <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    First Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="John"
                  />
                 
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-last-name"
                  >
                    Last Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="text"
                    placeholder="Doe"
                  />
                </div>
              </div> */}

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    Password
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-password"
                    type="password"
                    placeholder="******************"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <p className="text-gray-600 text-xs italic">
                    Make it stronger
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    Confirm Password
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-password"
                    type="password"
                    placeholder="******************"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <p className="text-gray-600 text-xs italic">
                    Make it stronger
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <input
                    // type="checkbox"
                    type="checkbox"
                    id="gutter"
                    name="gutter"
                    // checked={formData.gutter}
                    // onChange={handleChange}
                    class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    // id="hs-checkbox-group-6"
                    required
                  />
                  <label
                    for="hs-checkbox-group-6"
                    style={{ marginLeft: "10px" }}
                    // class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                  >
                    By clicking Sign Up, you agree to our{" "}
                    <Link to="/terms&conditions">
                      <span
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        Terms and condition
                      </span>{" "}
                    </Link>
                    &{" "}
                    <Link to="/privacypolicy">
                      <span
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        Privacy Policy.
                      </span>
                    </Link>
                  </label>
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <button
                    className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-password"
                    style={{
                      backgroundColor: "green",
                      fontWeight: "bold",
                      color: "white",
                    }}
                    // type="button"
                  >
                    Register
                  </button>
                </div>
              </div>
              <p
                style={{
                  marginTop: "20px",
                  // backgroundColor: "#f36e21",
                  marginLeft: "10%",
                  marginRight: "10%",
                }}
                class="font-light text-center text-black-500 dark:text-gray-400 sm:text-xl"
              >
                Have an account?{" "}
                <Link to="/login&payment">
                  <span style={{ color: "blue", textDecoration: "underline" }}>
                    login
                  </span>
                </Link>
              </p>
            </form>
            {/* <a
              href="#"
              className="flex font-semibold text-indigo-600 text-sm mt-10"
            >
              <svg
                className="fill-current mr-2 text-indigo-600 w-4"
                viewBox="0 0 448 512"
              >
                <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
              </svg>
              Continue Shopping
            </a> */}
          </div>

          <div id="summary" className="w-1/4 px-8 py-10">
            <h1 className="font-semibold text-2xl border-b pb-8">
              Order Summary
            </h1>
            <div className="flex justify-between mt-10 mb-5">
              <span className="font-semibold text-sm uppercase">
                Items
                {/* {trueProperties?.length} */}
              </span>
              <span className="font-semibold text-sm">
                ${total_Price.toFixed(2)}
              </span>
            </div>

            <div className="py-10">
              {files?.bluePrint != null ? (
                <>
                  <span className="font-semibold text-sm uppercase">
                    Blue Print: {files?.bluePrint}
                  </span>
                </>
              ) : (
                <>
                  <img src={screenshot} alt="abc" />
                </>
              )}
            </div>

            <div className="border-t mt-8">
              <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                <span>Total cost</span>
                <span>${total_Price.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
