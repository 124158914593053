export default function priceCalculator(
  service,
  propertyType,
  properitySize,
  buildings
) {
  if (service === "MATRoof") {
    if (propertyType === "residential") {
      if (properitySize === "small") {
        return 19.95;
      } else {
        return 34.95;
      }
    } else {
      if (properitySize === "small") {
        return 49.95;
      } else {
        if (buildings >= 2) {
          return 69.95 + 10 * (buildings - 1);
        } else {
          return 69.95;
        }
      }
    }
  } else if (service === "MATRoofα") {
    if (propertyType === "residential") {
      if (properitySize === "small") {
        return 7.95;
      } else {
        return 9.95;
      }
    }
  } else if (service === "MATGutter") {
    if (propertyType === "residential") {
      if (properitySize === "small") {
        return 9.95;
      } else {
        return 14.95;
      }
    } else {
      if (properitySize === "small") {
        return 19.95;
      } else {
        if (buildings >= 2) {
          return 24.95 + 10 * (buildings - 1);
        } else {
          return 24.95;
        }
      }
    }
  } else if (service === "MATSideX") {
    if (propertyType === "residential") {
      if (properitySize === "small") {
        return 44.95;
      } else {
        return 64.95;
      }
    } else {
      if (properitySize === "small") {
        return 80.0;
      } else {
        return 100.0;
      }
    }
  } else if (service === "MATPainter") {
    if (propertyType === "residential") {
      if (properitySize === "small") {
        return 44.95;
      } else {
        return 64.95;
      }
    } else {
      if (properitySize === "small") {
        return 80.0;
      } else {
        return 100.0;
      }
    }
  } else {
    //Mat concrete
    if (propertyType === "residential") {
      if (properitySize === "small") {
        return 9.95;
      } else {
        return 19.95;
      }
    } else {
      if (properitySize === "small") {
        return 34.95;
      } else {
        if (buildings >= 2) {
          return 54.95 + 10 * (buildings - 1);
        } else {
          return 54.95;
        }
      }
    }
  }
}
