// components/FileUpload.js

import React, { useState } from 'react';
import axios from 'axios';
import BASE_URL from '../../services/BASE_URL';

const FileUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${BASE_URL}/api/files/upload`, formData);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="file-input">Choose a file
  <input type="file" id="file-input" onChange={handleFileChange} />

  </label>
      {file && <button type="submit">Upload</button>}
    </form>
  );
};

export default FileUpload;