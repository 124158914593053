import { useEffect } from "react";
import Bar from "../components/header/Bar";
import Footer from "../components/navigation/Footer";
import NavBar from "../components/navigation/NavBar";
import ProductCatalog from "../components/productCatalog/ProductCatalog";
import BottomCard from "../components/BottomCard";
import ScrollToTopOnRefresh from "../components/ScrollToTopOnRefresh";
import ProductBottomCard from "../components/ProductVottomCard";
import { Helmet } from "react-helmet";
export default function Product() {
  const a = "home > Prouducts";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const breadcrumbJsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Contact Us",
        item: "https://matestimates.com/contact",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Our Pricing",
        item: "https://matestimates.com/pricing",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          Your One-Stop Shop for material Estimates on Roof, Siding, Paint ,
          Gutter and concrete
        </title>

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJsonLd) }}
        />

        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="MatEstimates" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Your One-Stop Shop for Material Estimates on Roof, Siding, Paint, Gutter, and Concrete"
        />
        <meta
          property="og:description"
          content="Explore our comprehensive product catalog featuring accurate construction material estimates for roofing, siding, painting, and concrete."
        />
        <meta property="og:url" content="https://matestimates.com/products" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@matestimates" />
        <meta
          name="twitter:title"
          content="Your One-Stop Shop for Material Estimates on Roof, Siding, Paint"
        />
        <meta
          name="twitter:description"
          content="Explore our comprehensive product catalog featuring accurate construction material estimates for roofing, siding, painting, and concrete."
        />
        <meta
          name="twitter:image"
          content="https://matestimates.com/static/media/Why-Choos.6d7a32a45d932d262a0a.jpg"
        />

        <meta
          name="description"
          content="Explore our comprehensive product catalog featuring accurate construction material estimates for roofing, siding, painting, and concrete"
        />
      </Helmet>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Products</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>
      <ProductCatalog />
      {/* <div className="how-card-cont">
        <div className="how-left">
          <p style={{ textAlign: "left" }}>
            Sample of ROOF <br />
            Estimating Services
          </p>
        </div>
        <div className="how-right">
          <p style={{ textAlign: "left" }}>
            Sample of ROOF <br />
            Estimating Services
          </p>
        </div>
      </div> */}
      <ProductBottomCard />
      <Footer />
    </>
  );
}
