import AboutPic from "../../assets/Why-Choos.jpg";
import "./style.css";
export default function Bar() {
  return (
    <div className="wrapper-about">
      {/* <div style={{ marginBottom: "20px" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "36px",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          About MAT Estimates
        </p>
        <p style={{ textAlign: "center", maxWidth: "700px", margin: "0 auto" }}>
          Our comprehensive service specializes in accurate material estimation
          for roofing, gutters, siding, paint, and concrete projects. We
          understand the importance of precise calculations to ensure efficient
          resource allocation and cost-effectiveness. Whether you're planning a
          roofing overhaul, gutter installation, siding replacement, paint job,
          or concrete project, our expertise ensures that you receive detailed
          and reliable material estimates. Trust us to streamline your planning
          process, minimize waste, and help you achieve successful outcomes for
          your construction or renovation endeavors.
        </p>
      </div> */}
      <div
        // style={{ margin: "0 auto" }}
        className="about-cont"
      >
        <div style={{ marginRight: "100px", marginLeft: "20px" }}>
          {/* <div
            style={{
              // border: "1px solid #f36e21",
              padding: "10px",
              marginLeft: "90px",
              marginRight: "90px",
              maxHeight: "90px",
              // maxWidth:'300px',
              marginBottom:'30px'
            }}
          >
            <h2
              style={{
                fontWeight: "bold",
                textAlign: "left",
                fontSize: "50px",
                color:'#f36e21'
              }}
            >
              About MAT Estimates
            </h2>
           
          </div> */}
          <h2
            style={{
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "50px",
              color: "#f36e21",
              marginBottom: "10px",
            }}
          >
            About MAT Estimates
          </h2>
          <p style={{textAlign:'justify'}}>
            Welcome to MAT Estimates, your reliable partner in accurate <br />{" "}
            and comprehensive estimation services. As a dedicated estimation{" "}
            <br />
            company, we specialize in providing detailed take-off reports for{" "}
            <br />
            various construction elements, ensuring that you have the precise{" "}
            <br />
            information you need for your projects. Our expertise covers a range{" "}
            <br />
            of areas, including:
            <br />
            <span class="check-icon">✔</span> Roof <br />
            <span class="check-icon">✔</span> Gutters <br />
            <span class="check-icon">✔</span> Paint <br />
            <span class="check-icon">✔</span> Concrete <br />
            <span class="check-icon">✔</span> Siding <br />
            At MAT Estimates, we understand the critical role that accurate{" "}
            <br />
            estimates play in the success of construction projects. With <br />{" "}
            years of experience in the industry, we have honed our skills to
            become a <br />
            trusted name in estimation services.
          </p>
        </div>
        <img className="about-pic" src={AboutPic} alt="ServicePic" />
      </div>
    </div>
  );
}
