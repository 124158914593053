import { Grid } from "@mui/material";
// import { Link } from "react-router-dom";
import Bar from "../components/header/Bar";
import Footer from "../components/navigation/Footer";
import NavBar from "../components/navigation/NavBar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRegisterMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ScrollToTopOnRefresh from "../components/ScrollToTopOnRefresh";

export default function Signup() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "home > SignUp";
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        const res = await register({
          fname,
          lname,
          email,
          contact,
          company,
          password,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        toast.success('Register Successfully')
        navigate("/");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };
  return (
    <>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Signup</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      <Grid container marginBottom={"50px"}>
        <Grid item sm={12} md={12} lg={12}>
          <section
            style={{
              backgroundColor: "#f36e21",
              //   padding: "10px",
              margin: "0 auto",
              borderRadius: "20px",
              marginTop: "50px",
              maxWidth: "500px",

              // paddingBottom:'10px',
            }}
            className="bg-white dark:bg-gray-900"
          >
            <div class="py-8 lg:py-6 px-4 mx-auto max-w-screen-md">
              <div
                style={{
                  border: "1px solid white",
                  width: "inherit",
                  maxHeight: "90px",
                  // borderImage: "linear-gradient(to right, green, black)",
                  // borderImageSlice: 1,
                  boxSizing: "border-box",
                }}
              >
                <h2 class="mt-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                  Create Account
                </h2>

                <p
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#f36e21",
                    marginLeft: "10%",
                    marginRight: "10%",
                  }}
                  class="font-light text-center text-black-500 dark:text-gray-400 sm:text-xl"
                >
                  Have an account?{" "}
                  <Link to="/login">
                    <span
                      style={{ color: "white", textDecoration: "underline" }}
                    >
                      login
                    </span>
                  </Link>
                </p>
              </div>
              <form onSubmit={submitHandler} className="space-y-3 p-10">
                <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    First Name*
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="name"
                    // placeholder='Enter name'
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="John"
                    required
                  />
                </div>
                <div>
                  <label
                    for="text"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Last Name*
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="name"
                    // id="email"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Doe"
                    required
                  />
                </div>

                <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    // className=""
                  >
                    Email*
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="email"
                    // placeholder='Enter email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="info@matestimates.com"
                    required
                  />
                </div>

                <div>
                  <label
                    for="subject"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    // className=""
                  >
                    Contact Number
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="text"
                    // id="subject"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                    className="contact-input block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="+00 000 000 000"
                    required
                  />
                </div>
                <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    // className=""
                  >
                    Company Name*
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="text"
                    // id="email"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="MAT Estimates"
                    required
                  />
                </div>
                <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    // className=""
                  >
                    Password
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    // placeholder="name@flowbite.com"
                    required
                  />
                </div>
                <div>
                  <label
                    for="email"
                    className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    // className=""
                  >
                    Confirm Password
                  </label>
                  <input
                    style={{
                      borderRadius: "40px",
                      border: "1px solid black",
                      padding: "15px",
                    }}
                    type="password"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="contact-input shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    // placeholder="name@flowbite.com"
                    required
                  />
                </div>
                <div>
                  <input
                    // type="checkbox"
                    type="checkbox"
                    id="gutter"
                    name="gutter"
                    // checked={formData.gutter}
                    // onChange={handleChange}
                    class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    // id="hs-checkbox-group-6"
                    required
                  />
                  <label
                    for="hs-checkbox-group-6"
                    style={{ marginLeft: "10px" }}
                    // class="text-sm text-gray-500 ms-3 dark:text-gray-400"
                  >
                    By clicking Sign Up, you agree to our{" "}
                    <Link to="/terms&conditions">
                      <span
                        style={{ color: "white", textDecoration: "underline" }}
                      >
                        Terms and condition
                      </span>{" "}
                    </Link>
                    &{" "}
                    <Link to="/privacypolicy">
                      <span
                        style={{ color: "white", textDecoration: "underline" }}
                      >
                        Privacy Policy.
                      </span>
                    </Link>
                  </label>
                </div>
                {/* <div class="sm:col-span-2">
                <label
                  for="message"
                  className="contact-label block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  // className=""
                >
                  Your message
                </label>
                <textarea
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    padding: "15px",
                    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.5)",
                  }}
                  id="message"
                  rows="6"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Leave a comment..."
                ></textarea>
              </div> */}
                <div style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    style={{ backgroundColor: "grey", borderRadius: "30px" }}
                    class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
          </section>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
