import React from "react";
import Roof from "../../assets/Catalog-logos/MATROOFLogo.svg";
// import Roof from "../../assets/Catalog-logos/MATROOFLogo.svg";
import RoofX from "../../assets/Catalog-logos/MATROOFAlphaLogo.svg";
import SideX from "../../assets/Catalog-logos/MATSIDEX.svg";
import Painter from "../../assets/Catalog-logos/Painter.svg";
import Concrete from "../../assets/Catalog-logos/Concrete.svg";
import Gutter from "../../assets/Catalog-logos/MATGUTTER.svg";
import "./style.css";

export default function Table() {
  return (
    // Roof
    <div style={{ overflow: "auto" }}>
      <table
        className="price-table"
        style={{
          borderCollapse: "collapse",
          margin: "0 auto",
          marginTop: "30px",
        }}
      >
        <tbody>
          <tr
            style={{
              background: "#f36e21",
              color: "white",
              textAlign: "center",
            }}
          >
            <td style={{ textAlign: "center", padding: "20px" }}>Product</td>
            <td style={{ textAlign: "center" }}>Type</td>
            <td style={{ textAlign: "center" }}>Product Type</td>
            <td style={{ textAlign: "center" }}>No. of bldgs.</td>
            <td style={{ textAlign: "center" }}>Retail Pricing</td>
          </tr>
          <tr>
            <td
              style={{ textAlign: "center", borderBottom: "4px solid #f36e21" }}
              rowspan="6"
            >
              <img
                style={{ maxHeight: "50px", margin: "0 auto" }}
                src={Roof}
                alt="Logo"
              />
            </td>
            <td
              style={{
                borderBottom: "2px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#F8F4EC",
                fontWeight: "bold",
              }}
              rowspan="2"
            >
              Residential
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 50 SQ
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $19.95
            </td>
          </tr>
          <tr>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              Greater than 50 SQ
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              01
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              $34.95
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#E6BAA3",
                fontWeight: "bold",
              }}
              rowspan="3"
            >
              Commercial
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 100 SQ
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $49.95
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>Greater than 100 SQ</td>
            <td style={{ textAlign: "center" }}>01</td>
            <td style={{ textAlign: "center" }}>$69.95</td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              Greater than 100 SQ(2-20 bldg)
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              1-20
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              $69.95+10 per Bldg
            </td>
          </tr>

          {/* <tr>
        <td style={{textAlign:'center'}} rowspan="2">A.2</td>
        <td style={{textAlign:'center'}}>A.3.1</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.3.2</td>
      </tr> */}
          {/* <!--continue the pattern here--> */}
        </tbody>

        {/* Roof Alpha */}
        <tbody>
          {/* <tr style={{background:'#f36e21', color:'white', textAlign:'center'}}>
        <td style={{textAlign:'center', padding:'20px'}}>Description</td>
        <td style={{textAlign:'center'}}>Type</td>
        <td style={{textAlign:'center'}}>Description</td>
        <td style={{textAlign:'center'}}># of bluds</td>
        <td style={{textAlign:'center'}}>Retail Pricing</td>
      </tr> */}
          <tr>
            <td
              style={{ textAlign: "center", borderBottom: "4px solid #f36e21" }}
              rowspan="6"
            >
              <img
                style={{ maxHeight: "50px", margin: "0 auto" }}
                src={RoofX}
                alt="Logo"
              />
            </td>
            <td
              style={{
                borderBottom: "2px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#F8F4EC",
                fontWeight: "bold",
              }}
              rowspan="2"
            >
              Residential
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 50 SQ
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $7.95
            </td>
          </tr>
          <tr>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              Greater than 50 SQ
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              01
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              $9.95
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#E6BAA3",
                fontWeight: "bold",
              }}
              rowspan="3"
            >
              Commercial
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              ---
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              ---
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              ---
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>---</td>
            <td style={{ textAlign: "center" }}>---</td>
            <td style={{ textAlign: "center" }}>---</td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            ></td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            ></td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              .
            </td>
          </tr>

          {/* <tr>
        <td style={{textAlign:'center'}} rowspan="2">A.2</td>
        <td style={{textAlign:'center'}}>A.3.1</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.3.2</td>
      </tr> */}
          {/* <!--continue the pattern here--> */}
        </tbody>
        {/* SIdex */}
        <tbody>
          {/* <tr style={{background:'#f36e21', color:'white', textAlign:'center'}}>
        <td style={{textAlign:'center', padding:'20px'}}>Description</td>
        <td style={{textAlign:'center'}}>Type</td>
        <td style={{textAlign:'center'}}>Description</td>
        <td style={{textAlign:'center'}}># of bluds</td>
        <td style={{textAlign:'center'}}>Retail Pricing</td>
      </tr> */}
          <tr>
            <td
              style={{ textAlign: "center", borderBottom: "4px solid #f36e21" }}
              rowspan="6"
            >
              <img
                style={{ maxHeight: "50px", margin: "0 auto" }}
                src={SideX}
                alt="Logo"
              />
            </td>
            <td
              style={{
                borderBottom: "2px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#F8F4EC",
                fontWeight: "bold",
              }}
              rowspan="2"
            >
              Residential
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 40 SQ
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $44.95
            </td>
          </tr>
          <tr>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              Greater than 40 SQ
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              01
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              $64.95
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#E6BAA3",
                fontWeight: "bold",
              }}
              rowspan="3"
            >
              Commercial
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 100 SQ
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $80
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>Greater than 100 SQ</td>
            <td style={{ textAlign: "center" }}>01</td>
            <td style={{ textAlign: "center" }}>$100</td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              ---
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              ---
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              ---
            </td>
          </tr>

          {/* <tr>
        <td style={{textAlign:'center'}} rowspan="2">A.2</td>
        <td style={{textAlign:'center'}}>A.3.1</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.3.2</td>
      </tr> */}
          {/* <!--continue the pattern here--> */}
        </tbody>

        {/* Painter */}

        <tbody>
          {/* <tr style={{background:'#f36e21', color:'white', textAlign:'center'}}>
        <td style={{textAlign:'center', padding:'20px'}}>Description</td>
        <td style={{textAlign:'center'}}>Type</td>
        <td style={{textAlign:'center'}}>Description</td>
        <td style={{textAlign:'center'}}># of bluds</td>
        <td style={{textAlign:'center'}}>Retail Pricing</td>
      </tr> */}
          <tr>
            <td
              style={{ textAlign: "center", borderBottom: "4px solid #f36e21" }}
              rowspan="6"
            >
              <img
                style={{ maxHeight: "50px", margin: "0 auto" }}
                src={Painter}
                alt="Logo"
              />
            </td>
            <td
              style={{
                borderBottom: "2px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#F8F4EC",
                fontWeight: "bold",
              }}
              rowspan="2"
            >
              Residential
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 4000 SF
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $44.95
            </td>
          </tr>
          <tr>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              Greater than 4000 SF
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              01
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              $64.95
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#E6BAA3",
                fontWeight: "bold",
              }}
              rowspan="3"
            >
              Commercial
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 10000 SF
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $80
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>Greater than 10000 SF</td>
            <td style={{ textAlign: "center" }}>01</td>
            <td style={{ textAlign: "center" }}>$100</td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              ---
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              ---
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              ---
            </td>
          </tr>

          {/* <tr>
        <td style={{textAlign:'center'}} rowspan="2">A.2</td>
        <td style={{textAlign:'center'}}>A.3.1</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.3.2</td>
      </tr> */}
          {/* <!--continue the pattern here--> */}
        </tbody>

        {/* Concrete */}

        <tbody>
          {/* <tr style={{background:'#f36e21', color:'white', textAlign:'center'}}>
        <td style={{textAlign:'center', padding:'20px'}}>Description</td>
        <td style={{textAlign:'center'}}>Type</td>
        <td style={{textAlign:'center'}}>Description</td>
        <td style={{textAlign:'center'}}># of bluds</td>
        <td style={{textAlign:'center'}}>Retail Pricing</td>
      </tr> */}
          <tr>
            <td
              style={{ textAlign: "center", borderBottom: "4px solid #f36e21" }}
              rowspan="6"
            >
              <img
                style={{ maxHeight: "50px", margin: "0 auto" }}
                src={Concrete}
                alt="Logo"
              />
            </td>
            <td
              style={{
                borderBottom: "2px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#F8F4EC",
                fontWeight: "bold",
              }}
              rowspan="2"
            >
              Residential
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 100 CCF
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $9.95
            </td>
          </tr>
          <tr>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              Greater than 100 CCF
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              01
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              $19.95
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#E6BAA3",
                fontWeight: "bold",
              }}
              rowspan="3"
            >
              Commercial
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 1000 CCF
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $34.95
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>Greater than 1000 CCF</td>
            <td style={{ textAlign: "center" }}>01</td>
            <td style={{ textAlign: "center" }}>$54.95</td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              Greater than 1000 CCF (2-20 bldgs)
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              1-20
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              $54.95+10 per Bldg
            </td>
          </tr>

          {/* <tr>
        <td style={{textAlign:'center'}} rowspan="2">A.2</td>
        <td style={{textAlign:'center'}}>A.3.1</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.3.2</td>
      </tr> */}
          {/* <!--continue the pattern here--> */}
        </tbody>

        {/* Gutter */}

        <tbody>
          {/* <tr style={{background:'#f36e21', color:'white', textAlign:'center'}}>
        <td style={{textAlign:'center', padding:'20px'}}>Description</td>
        <td style={{textAlign:'center'}}>Type</td>
        <td style={{textAlign:'center'}}>Description</td>
        <td style={{textAlign:'center'}}># of bluds</td>
        <td style={{textAlign:'center'}}>Retail Pricing</td>
      </tr> */}
          <tr>
            <td
              style={{ textAlign: "center", borderBottom: "4px solid #f36e21" }}
              rowspan="6"
            >
              <img
                style={{ maxHeight: "50px", margin: "0 auto" }}
                src={Gutter}
                alt="Logo"
              />
            </td>
            <td
              style={{
                borderBottom: "2px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#F8F4EC",
                fontWeight: "bold",
              }}
              rowspan="2"
            >
              Residential
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 250 LF
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $9.95
            </td>
          </tr>
          <tr>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              Greater than 250 LF
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              01
            </td>
            <td
              style={{ borderBottom: "2px solid #f36e21", textAlign: "center" }}
            >
              $14.95
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#E6BAA3",
                fontWeight: "bold",
              }}
              rowspan="3"
            >
              Commercial
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              Less than 500 LF
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              01
            </td>
            <td style={{ textAlign: "center", backgroundColor: "#FFD1E3" }}>
              $19.95
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>Greater than 500 LF</td>
            <td style={{ textAlign: "center" }}>01</td>
            <td style={{ textAlign: "center" }}>$24.95</td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              Greater than 500 LF (2-20 bldgs)
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              1-20
            </td>
            <td
              style={{
                borderBottom: "4px solid #f36e21",
                textAlign: "center",
                backgroundColor: "#FFD1E3",
              }}
            >
              $24.95+10 per Bldg
            </td>
          </tr>

          {/* <tr>
        <td style={{textAlign:'center'}} rowspan="2">A.2</td>
        <td style={{textAlign:'center'}}>A.3.1</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.3.2</td>
      </tr> */}
          {/* <!--continue the pattern here--> */}
        </tbody>

        {/* <tbody>
     
      <tr>
        <td style={{textAlign:'center'}} rowspan="6"><img style={{height:'50px', margin:'0 auto'}} src={Roof} alt="Logo" /></td>
        <td style={{textAlign:'center'}} rowspan="2">A.1</td>
        <td style={{textAlign:'center'}}>A.1.1</td>
        <td style={{textAlign:'center'}}>A.1.1.1</td>
        <td style={{textAlign:'center'}}>A.1.1.1.1</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.1.2</td>
        <td style={{textAlign:'center'}}>A.1.3</td>
        <td style={{textAlign:'center'}}>A.1.4</td>

      </tr>
      <tr>
        <td style={{textAlign:'center'}} rowspan="3">A.2</td>
        <td style={{textAlign:'center'}}>A.2.1</td>
        <td style={{textAlign:'center'}}>A.2.1</td>
        <td style={{textAlign:'center'}}>A.2.1</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.2.2</td>
        <td style={{textAlign:'center'}}>A.2.2</td>
        <td style={{textAlign:'center'}}>A.2.2</td>
      </tr>
      <tr>
        <td style={{textAlign:'center'}}>A.2.2</td>
        <td style={{textAlign:'center'}}>A.2.2</td>
        <td style={{textAlign:'center'}}>A.2.2</td>
      </tr>
      
      
     
    </tbody> */}
      </table>
    </div>
  );
}
