import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import "./styles.css";

export default function CatalogHeader() {
  const navigate = useNavigate();
  const handleClick = (e) => {
    navigate("/");
  };
  return (
    <div className="catalog-header">
      <Grid
        container
        // spacing={5}
        alignItems={"center"}
        maxWidth={"1130px"}
        margin={"0 auto"}
        // bgcolor={'black'}
        // justifyContent={"center"}
      >
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <h1
            className="text-lg md:text-2xl lg:text-3xl md:text-center"
            style={{
              fontWeight: "bold",
              paddingLeft: 20,
              // fontSize: "36px",
              // fontSize: "100%",
              color: "wheat",
              // textAlign:'center'
            }}
          >
            Experience excellence with just a click!
          </h1>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Link to="section2" smooth={true} duration={500}>
            <div className="flex justify-center items-center md:my-6 sm:my-6">
              <button
                style={{
                  padding: 10,
                  // borderRadius: "30%",
                  paddingLeft: 40,
                  paddingRight: 40,
                  backgroundColor: "#3D3B40",
                  color: "wheat",
                  // fontSize: "16px",
                  margin: "0 auto",
                  // textAlignL:'center'
                }}
                onClick={handleClick}
                className="loc-btn mx-200"
              >
                Order Now
              </button>
            </div>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

{
  /* <Link to="section1" smooth={true} duration={500}>
                  <button
                    style={{ fontWeight: "bold" }}
                    onClick={toggleContent}
                    className="loc-btn"
                  >
                    Search
                  </button>
                </Link> */
}
