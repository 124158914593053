import { useEffect, useState } from "react";
// import './style.css'

export default function Bar() {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#808080",
        color: "white",
        // textAlign: "center",
        // position: "sticky",
        display: "flex",
        top: 0,
        padding: "5px",
        zIndex: 1000, // Adjust the z-index as needed
      }}
    >
      {/* <p style={{fontWeight:'bold', marginLeft:'10px',paddingRight:'10px'}}></p> */}
      <marquee>
        <p>
          Breaking News:<span style={{color:"#808080"}}>....</span> Comprehensive MATRoofα Report Unveiled in Just 2 Hours  
          <span style={{color:"#808080"}}>....</span> Unmatched Quality at an Unbelievable Price of $7.95!
        </p>
      </marquee>
    </div>
  );
}
