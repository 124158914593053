// src/redux/filesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const filesSlice = createSlice({
  name: 'files',
  initialState: {
    bluePrint: '',
    siteMap: '',
  },
  reducers: {
    setBluePrint: (state, action) => {
      state.bluePrint = action.payload;
    },
    setSiteMap: (state, action) => {
      state.siteMap = action.payload;
    },
  },
});

export const { setBluePrint, setSiteMap } = filesSlice.actions;
export const selectFiles = (state) => state.files;
export default filesSlice.reducer;
