import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/authSlice";
import { apiSlice } from "./slices/apiSlice";
import formReducer from "./slices/formSlice";
import locImageReducer from "./slices/locImageSlice";
import totalPriceReducer from "./slices/totalPriceSlice";
import filesReducer from "./slices/filesSlice";
import orderIdsReducer from "./slices/orderIdsSlice"; // Import the orderIdsSlice

// Define persist config
const persistConfig = {
  key: 'root',
  storage,
  // Optionally, you can whitelist or blacklist certain reducers
  // whitelist: ['auth'], // only 'auth' reducer will be persisted
  // blacklist: ['api'], // 'api' reducer won't be persisted
};

// Combine reducers
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  form: formReducer,
  image: locImageReducer,
  totalPrice: totalPriceReducer,
  files: filesReducer,
  orderIds: orderIdsReducer, // Include orderIdsReducer in the combined reducers
});

// Wrap your root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure your Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

// Create a persistor object
export const persistor = persistStore(store);

export default store;
