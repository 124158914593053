import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Bar from "../header/Bar";
import Footer from "../navigation/Footer";
import NavBar from "../navigation/NavBar";
import { useSelector } from "react-redux";
import { selectFormData, updateFormData } from "../../slices/formSlice";
import priceCalculator from "./PriceCalculator";
import MATGutter from "../../assets/Catalog-logos/MATGUTTER.svg";
import MATConcrete from "../../assets/Catalog-logos/Concrete.svg";
import MATPainter from "../../assets/Catalog-logos/Painter.svg";
import MATRoof from "../../assets/Catalog-logos/MATROOFLogo.svg";
import MATRoofα from "../../assets/Catalog-logos/MATROOFAlphaLogo.svg";
import MATSideX from "../../assets/Catalog-logos/MATSIDEX.svg";
// import { useSelector } from "react-redux";
import { selectImage } from "../../slices/locImageSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./styles.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ScrollToTopOnRefresh from "../ScrollToTopOnRefresh";
import BASE_URL from "../../services/BASE_URL";
import { useDispatch } from "react-redux";
import { setTotalPrice, selectTotalPrice } from "../../slices/totalPriceSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setBluePrint, setSiteMap } from "../../slices/filesSlice";
import { setOrderIds } from "../../slices/orderIdsSlice";
import InfoIcon from "@mui/icons-material/Info";
// import Form from "../InfoForm/Form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 800,
  // height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 15,
};

export default function Cart() {
  const screenshot = useSelector(selectImage);
  const { userInfo } = useSelector((state) => state.auth);
  const formData = useSelector(selectFormData);
  console.log("Im from cart", formData);
  const a = "home > cart";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  //custom icon

  //image sources

  const trueProperties = Object.entries(formData)
    .filter(([key, value]) => value === true)
    .map(([key, value]) => key);

  let combArray = [];
  // let total_price = 0;
  for (let i = 0; i < trueProperties?.length; i++) {
    const price = priceCalculator(
      trueProperties[i],
      formData?.propertyType,
      formData?.properitySize,
      formData?.buildings
    );

    // total_price += price;
    const propertyWithPrice = {
      property: trueProperties[i],
      auxOrMain: formData?.auxOrMain,
      description: formData?.description,
      buildings: formData?.buildings,
      properitySize: formData?.properitySize,
      propertyType: formData?.propertyType,
      location: formData?.location,
      price: price,
      userId: userInfo?._id,
      orderBy: userInfo?.fname + userInfo?.lname,
      company: userInfo?.company,
      contact: userInfo?.contact,
      email: userInfo?.email,
      bluePrint: formData?.bluePrint,
      sitemap: formData?.sitemap,
      report: "pending",
      payement: "pending",
      status: "Ordered",
    };

    combArray.push(propertyWithPrice);
    console.log("Im combined array", combArray);
    // console.log('Im price',price)
    // console.log(priceCalculator[i])
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(trueProperties);
  }, []);

  const getImageForProperty = (property) => {
    // Map property values to corresponding image imports
    const imageMap = {
      MATGutter,
      MATConcrete,
      MATPainter,
      MATRoof,
      MATRoofα,
      MATSideX,
    };

    // Get the corresponding image import for the property
    const image = imageMap[property];

    // If the property is not found in the map, you can handle it accordingly
    if (!image) {
      console.warn(`Image not found for property: ${property}`);
      return null;
    }

    return <img src={image} alt={property} />;
  };

  // const userId = userInfo?._id;
  // console.log('id mil gyi', userId)

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  // const handleOrders = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const userId = userInfo?._id; // Assuming userInfo contains the user details

  //     // Create a FormData object
  //     const formData = new FormData();

  //     // Iterate through the array and append each order data to formData
  //     for (const order of combinedArray) {
  //       // Append non-file fields
  //       Object.entries(order).forEach(([key, value]) => {
  //         if (key !== "sitemap" && key !== "bluePrint") {
  //           formData.append(key, value);
  //         }
  //       });

  //       // Append file fields
  //       if (order.sitemap) {
  //         formData.append("sitemap", order.sitemap);
  //       }
  //       if (order.bluePrint) {
  //         formData.append("bluePrint", order.bluePrint);
  //       }

  //       // Make a POST request with formData
  //       const response = await axios.post(
  //         `${BASE_URL}/orders/create-order`,
  //         formData,
  //         {
  //           headers: {
  //             // "x-user-id": userId,
  //             "Content-Type": "multipart/form-data", // Set content type for FormData
  //           },
  //         }
  //       );

  //       // Handle the response (e.g., display a success message)
  //       console.log("Order created successfully:", response.data);
  //     }

  //     toast.success("Product added successfully");
  //     handleClose();
  //   } catch (error) {
  //     // Handle errors (e.g., display an error message)
  //     console.error("Error creating orders:", error.message);
  //     toast.error("Unable to add order");
  //   }
  // };

  // property: trueProperties[i],
  //     auxOrMain: formData?.auxOrMain,
  //     description: formData?.description,
  //     buildings: formData?.buildings,
  //     properitySize: formData?.properitySize,
  //     propertyType: formData?.propertyType,
  //     location: formData?.location,
  //     price: price,
  //     userId: userInfo?._id,
  //     orderBy: userInfo?.fname + userInfo?.lname,
  //     company: userInfo?.company,
  //     contact: userInfo?.contact,
  //     email: userInfo?.email,
  //     bluePrint: formData?.bluePrint,
  //     sitemap: formData?.sitemap,

  const [combinedArray, setCombinedArray] = useState(combArray);

  const [total_price, setTotal_Price] = useState(0);
  // useEffect(() => {
  const handleRemove = (indexToRemove) => {
    setCombinedArray((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
  };
  useEffect(() => {
    let calculatedTotalPrice = 0;

    for (let i = 0; i < combinedArray.length; i++) {
      // Check if combinedArray[i] is not undefined and has a price property
      if (combinedArray[i]?.price) {
        calculatedTotalPrice += combinedArray[i].price;
      }
    }

    // Update the state with the calculated total price
    setTotal_Price(calculatedTotalPrice);
    dispatch(setTotalPrice(calculatedTotalPrice));
  }, [combinedArray]);
  useEffect(() => {
    if (combinedArray?.length === 0) {
      dispatch(updateFormData(initialState));
    }
  }, [combinedArray]);

  const formDataArray = [];

  for (let i = 0; i < combinedArray.length; i++) {
    const propertyWithPrice = combinedArray[i];

    const formData = new FormData();
    const generateUniqueName = (originalName) => {
      const timestamp = Date.now();
      const randomComponent = Math.floor(Math.random() * 1000); // Add a random component
      return `${timestamp}_${randomComponent}_${originalName}`;
    };

    formData.append("property", propertyWithPrice.property);
    // formData.append("auxOrMain", propertyWithPrice.auxOrMain);
    if (propertyWithPrice.auxOrMain != null) {
      formData.append("auxOrMain", propertyWithPrice.auxOrMain);
    }

    if (propertyWithPrice.description != null) {
      formData.append("description", propertyWithPrice.description);
    }
    // formData.append("description", propertyWithPrice.description);
    formData.append("buildings", propertyWithPrice.buildings);
    formData.append("properitySize", propertyWithPrice.properitySize);
    formData.append("propertyType", propertyWithPrice.propertyType);
    if (
      propertyWithPrice.location.address != null &&
      propertyWithPrice.location.link != null
    ) {
      formData.append("locAddress", propertyWithPrice.location.address);
      formData.append("locLink", propertyWithPrice.location.link);
    }
    formData.append("price", propertyWithPrice.price);
    formData.append("userId", propertyWithPrice.userId);
    formData.append("orderBy", propertyWithPrice.orderBy);
    formData.append("company", propertyWithPrice.company);
    formData.append("contact", propertyWithPrice.contact);
    formData.append("email", propertyWithPrice.email);
    // formData.append("report", propertyWithPrice.report);
    formData.append("status", propertyWithPrice.status);
    formData.append("payement", propertyWithPrice.payement);
    // formData.append("bluePrint", propertyWithPrice.bluePrint);
    // formData.append("sitemap", propertyWithPrice.sitemap);
    if (propertyWithPrice.bluePrint != null) {
      const modifiedBlueprintName = generateUniqueName(
        propertyWithPrice?.bluePrint?.name
      );
      formData.append(
        "bluePrint",
        new File([propertyWithPrice.bluePrint], modifiedBlueprintName)
      );
    }

    if (propertyWithPrice.sitemap != null) {
      const modifiedSitemapName = generateUniqueName(
        propertyWithPrice?.sitemap?.name
      );
      formData.append(
        "sitemap",
        new File([propertyWithPrice?.sitemap], modifiedSitemapName)
      );
    }

    formDataArray.push(formData);
  }

  console.log("Im form Data array", formDataArray);
  const initialState = {
    location: {},
    propertyType: "",
    properitySize: "",
    buildings: 0,
    auxOrMain: "",
    description: "",
    MATGutter: false,
    MATRoofα: false,
    MATSideX: false,
    MATRoof: false,
    MATPainter: false,
    MATConcrete: false,
    sitemap: null,
    bluePrint: null,
  };
  const handleOrders = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const userId = userInfo?._id; // Assuming userInfo contains the user details
      const orderIds = [];
      // Iterate through the array and make a POST request for each order

      for (const formData of formDataArray) {
        const response = await axios.post(
          `${BASE_URL}/orders/create-order`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // Handle the response and extract the order ID
        console.log("Response from server:", response); // Assuming the response contains the ID
        const orderId = response.data.order._id;
        orderIds.push(orderId); // Store the ID in the array
        // Handle the response (e.g., display a success message)
        console.log("Order created successfully:", response.data);
      }
      console.log("mil gyi ids", orderIds);
      dispatch(setOrderIds(orderIds));
      toast.success("Product added successfully");
      dispatch(updateFormData(initialState));
      dispatch(setTotalPrice(""));
      // handleClose();
    } catch (error) {
      // Handle errors (e.g., display an error message)
      console.error("Error creating orders:", error.message);
      toast.error("unable to add order");
    } finally {
      setIsLoading(false);
      navigate("/profile");
      // handleOpen();
    }
  };

  // }, combArray);
  dispatch(setBluePrint(formData?.bluePrint?.name));
  dispatch(setSiteMap(formData?.sitemap?.name));
  const [information, setInformation] = useState(
    "At the time of delivery, Price may be vary. Depending on the size of project "
  );

  const handlePreview = (file) => {
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      window.open(previewUrl, "_blank");
    }
  };

  const [checkoutUrl, setCheckoutUrl] = useState("");

  const handleCheckout = async () => {
    try {
      // setLoading(true);
      const response = await axios.post(`${BASE_URL}/api/checkout`, {
        // Pass any necessary data in the request body
        items: [
          { name: "MAT Products", price: Math.round(total_price), quantity: 1 },
        ], // Example data
      });
      setCheckoutUrl(response.data.url);
    } catch (error) {
      console.error("Error:", error.response.data.error); // Log any errors
    } finally {
      // setLoading(false);
    }
  };

  // Redirect to checkoutUrl when it's available
  if (checkoutUrl) {
    window.location = checkoutUrl;
  }

  return (
    <>
      <Bar />
      <NavBar />

      <ScrollToTopOnRefresh />

      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto", color: "white" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Cart</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      {/* lg screen view for cart*/}
      <div
        style={{ maxWidth: "1200px", overflowX: "scroll" }}
        className="large-view container mx-auto mt-10"
      >
        <div className="flex shadow-md my-10">
          <div
            // style={{ }}
            className="w-3/4 md:w-4/4 bg-white px-10 py-10"
          >
            <div className="flex justify-between border-b pb-8">
              <h1 className="font-semibold text-2xl">
                {combinedArray?.length ? (
                  <span style={{ marginRight: "20px", cursor: "pointer" }}>
                    <Link to="/edit-products">
                      <ArrowBackIcon />{" "}
                    </Link>
                  </span>
                ) : (
                  <></>
                )}
                Cart
              </h1>
              <h2 className="font-semibold text-2xl">
                {combinedArray?.length}
              </h2>
            </div>

            {combinedArray?.length !== 0 && formData?.description && (
              <p
                // href="#"
                className="flex font-semibold text-indigo-600 text-sm mt-10"
              >
                Description: <span style={{ color: "white" }}>.......</span>
                <span className="text-gray-600">{formData?.description}</span>
              </p>
            )}

            <div className="flex mt-10 mb-5">
              <h3 className="font-semibold text-gray-600 text-xs uppercase w-2/5">
                Product Details
              </h3>
              {formData?.sitemap?.name && (
                <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
                  siteMap
                </h3>
              )}

              <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
                Address
              </h3>
              <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
                Price
              </h3>
              <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
                Actions
              </h3>
              {/* <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
                Total
              </h3> */}
            </div>

            {combinedArray?.map((item, index) => (
              <div
                key={index}
                className="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5"
              >
                <div className="flex w-2/5">
                  <div className="w-20">
                    {getImageForProperty(item?.property)}
                  </div>
                  <div className="flex flex-col justify-between ml-4 flex-grow">
                    <span className="font-bold text-sm">{item?.property}</span>
                    <span className="text-red-500 text-xs">
                      {item?.auxOrMain}
                    </span>
                    {/* <a
               href="#"
               className="font-semibold hover:text-red-500 text-gray-500 text-xs"
             >
               Remove
             </a> */}
                  </div>
                </div>
                {formData?.sitemap?.name && (
                  <div className="flex justify-center font-semibold text-sm w-1/5">
                    <button
                      onClick={() => handlePreview(combinedArray[0]?.sitemap)}
                    >
                      <p>{`${item.sitemap.name.substring(0, 5)}.. ${
                        item.sitemap.name.includes(".")
                          ? `.${item.sitemap.name.split(".").pop()}`
                          : ""
                      }`}</p>
                    </button>
                  </div>
                )}

                <div className="flex justify-center font-semibold text-sm w-1/5">
                  {item?.location?.link && item?.location?.address ? (
                    <a href={item?.location?.link} target="_blank">
                      {item?.location?.address.substring(0, 9)} ...
                    </a>
                  ) : (
                    <>Nill</>
                  )}
                </div>
                <span className="text-center w-1/5 font-semibold text-sm">
                  ${item?.price}
                </span>
                <span className="text-center w-1/5 font-semibold text-sm">
                  <button
                    onClick={() => handleRemove(index)}
                    className="font-semibold hover:text-red-500 text-xs"
                  >
                    Remove
                  </button>
                </span>
                {/* <span className="text-center w-1/5 font-semibold text-sm">
           $400.00
         </span> */}
              </div>
            ))}
          </div>
          {total_price === 0 ? (
            <></>
          ) : (
            <>
              <div id="summary" className="cart-summary w-1/4 px-8 py-10">
                <h1 className="font-semibold text-2xl border-b pb-8">
                  Order Summary
                </h1>
                <div className="flex justify-between mt-10 mb-5">
                  <span className="font-semibold text-sm uppercase">
                    Items {combinedArray?.length}
                  </span>
                  <span className="font-semibold text-sm">
                    ${total_price.toFixed(2)}
                  </span>
                </div>

                <div className="py-10">
                  {combinedArray[0]?.bluePrint != null ? (
                    <>
                      <span className="font-semibold text-sm uppercase">
                        Blue Print :{" "}
                        <button
                          onClick={() =>
                            handlePreview(combinedArray[0]?.bluePrint)
                          }
                        >
                          {`${combinedArray[0]?.bluePrint?.name.substring(
                            0,
                            5
                          )}.. ${
                            combinedArray[0]?.bluePrint?.name.includes(".")
                              ? `.${combinedArray[0]?.bluePrint?.name
                                  .split(".")
                                  .pop()}`
                              : ""
                          }`}
                        </button>
                      </span>
                    </>
                  ) : (
                    <>
                      <img src={screenshot} alt="abc" />
                    </>
                  )}
                </div>

                <div className="border-t mt-8">
                  <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                    <span>Total Estimated cost</span>
                    <span>${total_price.toFixed(2)}</span>
                  </div>
                  {userInfo ? (
                    <>
                      {" "}
                      {/* <Link to="/payment"> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <p style={{ opacity: "0.5" }}>
                          Price may vary{" "}
                          <span style={{ color: "white" }}>.............</span>
                        </p>
                        <Tooltip
                          style={{ width: "30px", cursor: "pointer" }}
                          title={information}
                          arrow
                        >
                          <div>
                            {/* <p>Hello g waht are you doing</p> */}
                            <InfoIcon color="#7D7C7C" />
                          </div>
                        </Tooltip>
                      </div>
                      {isLoading ? (
                        <button
                          // onClick={handleOpen}
                          //  onClick={handleOrders}
                          className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full"
                        >
                          Submitting.......
                        </button>
                      ) : (
                        <button
                          // onClick={handleOpen}
                          onClick={handleOrders}
                          className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full"
                        >
                          Confirm Order
                        </button>
                      )}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            textAlign={"center"}
                          >
                            Congratulations
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, textAlign: "center" }}
                          >
                            Your Order Put successfully
                          </Typography>

                          <Button
                            sx={{ margin: "5px" }}
                            variant="contained"
                            onClick={handleCheckout}
                          >
                            Go for payement
                          </Button>
                          <br />
                          <Button
                            sx={{ margin: "5px" }}
                            variant="contained"
                            onClick={handleClose}
                          >
                            Pay Afer a while
                          </Button>
                        </Box>
                      </Modal>
                      {/* </Link> */}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <p style={{ opacity: "0.5" }}>
                          Price may vary{" "}
                          <span style={{ color: "white" }}>.............</span>
                        </p>
                        <Tooltip
                          style={{ width: "30px", cursor: "pointer" }}
                          title={information}
                          arrow
                        >
                          <div>
                            {/* <p>Hello g waht are you doing</p> */}
                            <InfoIcon color="#7D7C7C" />
                          </div>
                        </Tooltip>
                      </div>

                      <Link to="/register&payment">
                        <button className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">
                          Checkout
                        </button>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* sm screen view */}
      <div
        style={{ maxWidth: "1200px" }}
        className="small-view container mx-auto mt-10"
      >
        <div className="flex justify-between border-b pb-8 p-5">
          <h1 className="font-semibold text-2xl">
            {combinedArray?.length ? (
              <span style={{ marginRight: "20px", cursor: "pointer" }}>
                <Link to="/edit-products">
                  <ArrowBackIcon />{" "}
                </Link>
              </span>
            ) : (
              <></>
            )}
            Cart
          </h1>
          <h2 className="font-semibold text-2xl">{combinedArray?.length}</h2>
        </div>

        {combinedArray?.length !== 0 && formData?.description && (
          <p
            // href="#"
            className="flex font-semibold text-indigo-600 text-sm mt-10 p-5"
          >
            Description: <span style={{ color: "white" }}>.......</span>
            <span className="text-gray-600">{formData?.description}</span>
          </p>
        )}

        <div className="custom-scrollbar flex shadow-md">
          <div
            style={{ width: "1200px", margin: "0 auto" }}
            className=" bg-white px-10 py-10"
          >
            <div className="flex mt-10 mb-5">
              <h3
                style={{ padding: "10px" }}
                className="font-semibold text-gray-600 text-xs uppercase w-2/5"
              >
                Product Details
              </h3>
              {formData?.sitemap?.name && (
                <h3
                  style={{ padding: "10px" }}
                  className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center"
                >
                  siteMap
                </h3>
              )}
              <h3
                style={{ padding: "10px" }}
                className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center"
              >
                Address
              </h3>
              <h3
                style={{ padding: "10px" }}
                className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center"
              >
                Price
              </h3>
              <h3
                style={{ padding: "10px" }}
                className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center"
              >
                Actions
              </h3>

              {/* <h3 className="font-semibold text-center text-gray-600 text-xs uppercase w-1/5 text-center">
                Total
              </h3> */}
            </div>
            {combinedArray?.map((item, index) => (
              <div
                key={index}
                className="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5"
              >
                <div className="flex w-2/5">
                  {/* <div className="w-20">
                    {getImageForProperty(item?.property)}
                  </div> */}
                  <div
                    style={{ padding: "10px" }}
                    className="flex flex-col justify-between ml-4 flex-grow"
                  >
                    <span className="font-bold text-sm">{item?.property}</span>
                    <span className="text-red-500 text-xs">
                      {item?.auxOrMain}
                    </span>
                    {/* <a
               href="#"
               className="font-semibold hover:text-red-500 text-gray-500 text-xs"
             >
               Remove
             </a> */}
                  </div>
                </div>
                {formData?.sitemap?.name && (
                  <div
                    style={{ padding: "10px" }}
                    className="flex justify-center font-semibold text-sm w-1/5"
                  >
                    <button
                      onClick={() => handlePreview(combinedArray[0]?.sitemap)}
                    >
                      <p>{`${item.sitemap.name.substring(0, 5)}.. ${
                        item.sitemap.name.includes(".")
                          ? `.${item.sitemap.name.split(".").pop()}`
                          : ""
                      }`}</p>
                    </button>
                  </div>
                )}

                <div
                  style={{ padding: "10px" }}
                  className="flex justify-center font-semibold text-sm w-1/5"
                >
                  {item?.location?.link && item?.location?.address ? (
                    <a href={item?.location?.link} target="_blank">
                      {item?.location?.address.substring(0, 9)} ...
                    </a>
                  ) : (
                    <>Nill</>
                  )}
                </div>
                <span
                  style={{ padding: "10px" }}
                  className="text-center w-1/5 font-semibold text-sm"
                >
                  ${item?.price}
                </span>
                <span
                  style={{ padding: "10px" }}
                  className="text-center w-1/5 font-semibold text-sm"
                >
                  <button
                    onClick={() => handleRemove(index)}
                    className="font-semibold hover:text-red-500 text-xs"
                  >
                    Remove
                  </button>
                </span>
                {/* <span className="text-center w-1/5 font-semibold text-sm">
           $400.00
         </span> */}
              </div>
            ))}
            {/* <a
              href="#"
              className="flex font-semibold text-indigo-600 text-sm mt-10"
            >
              <svg
                className="fill-current mr-2 text-indigo-600 w-4"
                viewBox="0 0 448 512"
              >
                <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
              </svg>
              Continue Shopping
            </a> */}
          </div>
        </div>
        {total_price === 0 ? (
          <></>
        ) : (
          <>
            <div id="summary" className="cart-summary w-4/4 px-8 py-10">
              <h1 className="font-semibold text-2xl border-b pb-8">
                Order Summary
              </h1>
              <div className="flex justify-between mt-10 mb-5">
                <span className="font-semibold text-sm uppercase">
                  Items {combinedArray?.length}
                </span>
                <span className="font-semibold text-sm">
                  ${total_price.toFixed(2)}
                </span>
              </div>

              <div className="py-10">
                {combinedArray[0]?.bluePrint != null ? (
                  <>
                    <span className="font-semibold text-sm uppercase">
                      Blue Print :{" "}
                      <button
                        onClick={() =>
                          handlePreview(combinedArray[0]?.bluePrint)
                        }
                      >
                        {`${combinedArray[0]?.bluePrint?.name.substring(
                          0,
                          5
                        )}.. ${
                          combinedArray[0]?.bluePrint?.name.includes(".")
                            ? `.${combinedArray[0]?.bluePrint?.name
                                .split(".")
                                .pop()}`
                            : ""
                        }`}
                      </button>
                    </span>
                  </>
                ) : (
                  <>
                    <img src={screenshot} alt="abc" />
                  </>
                )}
              </div>

              <div className="border-t mt-8">
                <div className="flex font-semibold justify-between py-6 text-sm uppercase">
                  <span>Total Estimated cost</span>
                  <span>${total_price.toFixed(2)}</span>
                </div>
                {userInfo ? (
                  <>
                    {" "}
                    {/* <Link to="/payment"> */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <p style={{ opacity: "0.5" }}>
                        Price may vary{" "}
                        <span style={{ color: "white" }}>.............</span>
                      </p>
                      <Tooltip
                        style={{ width: "30px", cursor: "pointer" }}
                        title={information}
                        arrow
                      >
                        <div>
                          {/* <p>Hello g waht are you doing</p> */}
                          <InfoIcon color="#7D7C7C" />
                        </div>
                      </Tooltip>
                    </div>
                    {isLoading ? (
                      <button
                        // onClick={handleOpen}
                        //  onClick={handleOrders}
                        className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full"
                      >
                        Submitting.......
                      </button>
                    ) : (
                      <button
                        // onClick={handleOpen}
                        onClick={handleOrders}
                        className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full"
                      >
                        Confirm Order
                      </button>
                    )}
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          textAlign={"center"}
                        >
                          Congratulations
                        </Typography>
                        <Typography
                          id="modal-modal-description"
                          sx={{ mt: 2, textAlign: "center" }}
                        >
                          Your Order Put successfully
                        </Typography>
                        <Button
                          sx={{ margin: "5px" }}
                          variant="contained"
                          onClick={handleCheckout}
                        >
                          Go for payement
                        </Button>
                        <Button
                          sx={{ margin: "5px" }}
                          variant="contained"
                          onClick={handleClose}
                        >
                          Pay After a while
                        </Button>
                      </Box>
                    </Modal>
                    {/* </Link> */}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <p style={{ opacity: "0.5" }}>
                        Price may vary{" "}
                        <span style={{ color: "white" }}>.............</span>
                      </p>
                      <Tooltip
                        style={{ width: "30px", cursor: "pointer" }}
                        title={information}
                        arrow
                      >
                        <div>
                          {/* <p>Hello g waht are you doing</p> */}
                          <InfoIcon color="#7D7C7C" />
                        </div>
                      </Tooltip>
                    </div>

                    <Link to="/register&payment">
                      <button className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">
                        Checkout
                      </button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
