import React from "react";
import AbouPic from "../../assets/Why-Choos.jpg";
import FunFact from "./FunFact";
import { Button, Grid } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./style.css";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Slider from "./ServiceSlider";
import Accordion from "./Accordian";
import { Link } from "react-router-dom";
import "./services.css";
import AboutCard from "../About/AboutCards";

export default function Services() {
  return (
    <>
      <div className="catalog-header">
        <Grid
          container
          // spacing={5}
          alignItems={"center"}
          maxWidth={"1100px"}
          margin={"0 auto"}
          // bgcolor={'black'}
          // justifyContent={"center"}
        >
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <h1
              className="text-lg md:text-2xl lg:text-3xl md:text-center"
              style={{
                fontWeight: "bold",
                // marginLeft: "10px",
                // fontSize: "36px",
                // fontSize: "100%",
                color: "wheat",
                textAlign: "center",
              }}
            >
              Get your MAT estimates report now
            </h1>
            {/* <br /> */}
            {/* <p>
              {" "}
              Tenetur, sapiente. Reprehenderit, odio amet recusandae <br /> et
              maiores dignissimos eum?
            </p> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} alignItems={"center"}>
            <div className="flex justify-center items-center md:my-6 sm:my-6">
              <a
                href="https://api.matestimates.com/Sample_Report.pdf"
                target="_blank"
              >
                <button
                  style={{
                    padding: 10,
                    // borderRadius: "30%",
                    paddingLeft: 30,
                    paddingRight: 30,
                    backgroundColor: "#3D3B40",
                    color: "wheat",
                    // fontSize: "16px",
                  }}
                  className="loc-btn"
                >
                  Sample Report
                </button>
              </a>

              <Link to="/products">
                <button
                  style={{
                    padding: 10,
                    // borderRadius: "30%",
                    paddingLeft: 30,
                    paddingRight: 30,
                    backgroundColor: "#3D3B40",
                    color: "wheat",
                    // fontSize: "16px",
                  }}
                  className="loc-btn"
                >
                  Explore Products
                </button>
              </Link>
            </div>
          </Grid>
        </Grid>
        {/* <h1
          style={{
            fontWeight: "bold",
            marginLeft: "10px",
            fontSize: "100%",
            color: "wheat",
          }}
        >
          Start Using Eagle view now
        </h1>
        <button
          style={{
            padding: 10,
            // borderRadius: "30%",
            backgroundColor: "#808080",
            color: "wheat",
          }}
          className="loc-btn"
        >
          Request Demo
        </button>
        <button
          style={{
            padding: 10,
            // borderRadius: "30%",
            backgroundColor: "#808080",
            color: "wheat",
          }}
          className="loc-btn"
        >
          Explore and view report
        </button> */}
      </div>

      <div
        style={{ maxWidth: "1200px", textAlign: "center", margin: "0 auto" }}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: "50px",
            textAlign: "center",
            color: "#f36e21",
          }}
        >
          Our Services
        </p>
        <p
          style={{
            textAlign: "justify",
            maxWidth: "800px",
            margin: "0 auto",
            // justifyContent: "center",
            // alignItems:'center'
          }}
        >
          Our comprehensive service specializes in accurate material estimation
          for roofing, gutters, siding, paint, and concrete projects. We
          understand the importance of precise calculations to ensure efficient
          resource allocation and cost-effectiveness. Whether you're planning a
          roofing overhaul, gutter installation, siding replacement, paint job,
          or concrete project, our expertise ensures that you receive detailed
          and reliable material estimates. Trust us to streamline your planning
          process, minimize waste, and help you achieve successful outcomes for
          your construction or renovation endeavors.
        </p>
      </div>
      <Slider />
      <div className="service-cont">
        <img className="service-pic" src={AbouPic} alt="ServicePic" />
        <div>
          {/* <p
            style={{ fontWeight: "bold", fontSize: "20px", marginTop: "20px" }}
          >
            MAt is just $11 per report
          </p> */}
          <h3
            style={{
              //   height: "70vh",
              //   padding: "10vh",
              fontWeight: "bold",
              fontSize: "40px",
              color: "#f36e21",
            }}
          >
            Why Choose MAT Estimates?
          </h3>
          <p style={{ margin: "20px" }}>
            Explore our product offerings to find the right solution foryour
            construction <br />
            needs. If you have specific requirements or questions, our team is
            here to
            <br />
            assist you. Choose MAT Estimates for precision, reliability, and
            excellence <br />
            excellence in estimation services.
            <br />
            <Accordion
              isOpen={true}
              title={"Specialized Expertise:"}
              answer={
                "Each product is meticulously designed to meet the unique requirements of its respective category, ensuring accurate and tailored estimates."
              }
            />
            <Accordion
              title={"User-Friendly Reports:"}
              answer={
                "Our reports are crafted for clarity and ease of use. Whether you're a seasoned professional or new to construction, our products provide valuable insights."
              }
            />
            <Accordion
              title={"Comprehensive Information:"}
              answer={
                "We go beyond basic estimates, providing detailed information that empowers you to make informed decisions and plan your projects with confidence."
              }
            />
            <Accordion
              title={"Timely Delivery:"}
              answer={
                "In the fast-paced construction industry, we understand the importance of timely information. Our reports are delivered promptly, allowing you to stay on schedule."
              }
            />
            <Accordion
              title={"Trusted Accuracy:"}
              answer={
                "With MAT Estimates, you can trust that our calculations are accurate and reliable, helping you avoid budget overruns and achieve project success."
              }
            />
            {/* <Button
              variant="contained"
              color="primary"
              startIcon={<PlayCircleIcon sx={{ color: "#f36e21" }} />}
              sx={{
                borderRadius: "50px",
                backgroundColor: "#DED0B6",
                color: "black",
                fontWeight: "bold",
                fontSize: "12px",
                width: "400px",
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "flex-start", // Align icon and label to the left
                "&:hover": {
                  backgroundColor: "#f36e21",
                  // padding: "10px 20px",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                },
              }}
            >
              Your Button Text
            </Button>
            <br />
            Explicabo eligendi culpa voluptatem, nemo quaerat aliquam <br />
            ea autem voluptate quisquam odit, ipsa voluptatibus repellat <br />
            sapiente velit. Incidunt nemo eos deleniti maxime. <br />
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayCircleIcon sx={{ color: "#f36e21" }} />}
              sx={{
                borderRadius: "50px",
                backgroundColor: "#DED0B6",
                color: "black",
                fontWeight: "bold",
                fontSize: "12px",
                width: "400px",
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "flex-start", // Align icon and label to the left
                "&:hover": {
                  backgroundColor: "#f36e21",
                  // padding: "10px 20px",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                },
              }}
            >
              Your Button Text
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayCircleIcon sx={{ color: "#f36e21" }} />}
              sx={{
                borderRadius: "50px",
                backgroundColor: "#DED0B6",
                color: "black",
                fontWeight: "bold",
                fontSize: "12px",
                width: "400px",
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "flex-start", // Align icon and label to the left
                "&:hover": {
                  backgroundColor: "#f36e21",
                  // padding: "10px 20px",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                },
              }}
            >
              Your Button Text
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayCircleIcon sx={{ color: "#f36e21" }} />}
              sx={{
                borderRadius: "50px",
                backgroundColor: "#DED0B6",
                color: "black",
                fontWeight: "bold",
                fontSize: "12px",
                width: "400px",
                marginTop: "10px",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "flex-start", // Align icon and label to the left
                "&:hover": {
                  backgroundColor: "#f36e21",
                  // padding: "10px 20px",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                },
              }}
            >
              Your Button Text
            </Button> */}
          </p>
        </div>
      </div>
      <div className="sm-hide-card">
      <FunFact />
      </div>
      <div className="sm-show-card">
      <AboutCard />
      </div>
    </>
  );
}
