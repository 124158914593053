import { useEffect } from 'react';

const ScrollToTopOnRefresh = () => {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);

    // Add an event listener for beforeunload to scroll to top on refresh
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return null; // Render nothing (or a placeholder) since this is a utility component
};

export default ScrollToTopOnRefresh;
