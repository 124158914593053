// Modal.js

import React, { useEffect } from "react";
import Bar from "../header/Bar";
import NavBar from "../navigation/NavBar";
import Footer from "../navigation/Footer";
import Table from "./Table";
import HowWeDo from "../About/HowWeDo";
import BottomCard from "../BottomCard";
import { Helmet } from "react-helmet";

const PriceModel = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "Home > Pricing";

  const breadcrumbJsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Our Products",
        item: "https://matestimates.com/products",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "About Us",
        item: "https://matestimates.com/about",
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>
          Our Pricing - List Estimation Services for estimation Projects Price &
          Get precise pricing and Estimation information to streamline your next
          estimation project
        </title>

        {JSON.stringify(breadcrumbJsonLd)}

        <meta
          name="description"
          content="MatEstimates provides very effective price for any trade takeoff along material estimation services. Get a quote today"
        />
        <meta
          name="keywords"
          content="roof measurements, aerial roof measurements, roof measurement reports, roof measurement companies, roof line measurements, i roofing measurements, aerial roof measurements, aerial roof measurement services, satellite measurement of roof, eagleview, roofing measurements companies near me, roofing measurements near me"
        />

        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="MatEstimates" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Get Precise Pricing and Estimation Information to Streamline Your Next Estimation Project"
        />
        <meta
          property="og:description"
          content="MatEstimates provides very effective pricing for any trade takeoff along with material estimation services. Get a quote today."
        />
        <meta property="og:url" content="https://matestimates.com/pricing" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@matestimates" />
        <meta
          name="twitter:title"
          content="List of Estimation Services for Estimation Projects' Pricing"
        />
        <meta
          name="twitter:description"
          content="MatEstimates provides very effective pricing for any trade takeoff along with material estimation services. Get a quote today."
        />
        <meta
          name="twitter:image"
          content="https://matestimates.com/static/media/Why-Choos.6d7a32a45d932d262a0a.jpg"
        />
      </Helmet>
      <Bar />
      <NavBar />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto", color: "white" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>About Us</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>
      <div
        style={{ maxWidth: "1200px", textAlign: "center", margin: "0 auto" }}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: "50px",
            textAlign: "center",
            color: "#f36e21",
            marginTop: "15px",
          }}
        >
          MAT Pricing
        </p>
        <p
          style={{
            textAlign: "center",
            maxWidth: "800px",
            marginBottom: "30px",
            margin: "0 auto",

            // justifyContent: "center",
            // alignItems:'center'
          }}
        >
          Our comprehensive service specializes in accurate material estimation
          for roofing, gutters, siding, paint, and concrete projects.
        </p>
      </div>

      <Table />
      {/* <HowWeDo /> */}
      <div style={{ marginTop: "100px" }}></div>
      <BottomCard />
      <Footer />
    </>
  );
};

export default PriceModel;
