import Typography from "@mui/material/Typography";
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  // geocodeByLatLng,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { selectImage, setImage } from "../../slices/locImageSlice";
import Form from "../InfoForm/Form";
import "./Banner.css";
import { toast } from "react-toastify";
import locPic from "../../assets/ConfirmLoc.png";

const libraries = ["places"];

const CustomClickableArea = ({ position, onClick }) => (
  <OverlayView
    position={position}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    // getPixelPositionOffset={(width, height) => ({
    //   x: -width / 6,
    //   y: -height / 9,
    // })}
  >
    <div
      style={{
        position: "absolute",
        cursor: "pointer",
        width: "40px",
        height: "40px",
        marginBottom: -1000000000,
        backgroundColor: "rgba(255, 0, 0, 0.3)",
        borderRadius: "50%",
      }}
      onMouseDown={onClick}
      onTouchStart={onClick}
    />
  </OverlayView>
);

export default function Banner() {
  //for location image screenshot
  const dispatch = useDispatch();
  const selectedImage = useSelector(selectImage);
  //for scrolling
  const MapRef = useRef(null);
  const FormRef = useRef(null);
  //for location
  const [mapTypeId, setMapTypeId] = useState("satellite"); // Default to satellite view
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({
    lat: 31.5497,
    lng: 74.3436,
  }); // Default to Lahore
  const [location, setLocation] = useState({});

  const [address, setAddress] = useState("");

  const onMarkerDragEnd = (e) => {
    setMarkerPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    // setIsContentVisible(false)
  };
  const [selected, setSelected] = useState(false);
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      setAddress(selectedAddress);
      setSelected(true)
      setLocation({ ...location, address: selectedAddress });
      setMarkerPosition(latLng);
    } catch (error) {
      console.error("Error selecting place", error);
    }
  };

  const [shouldScroll, setShouldScroll] = useState(false);
  const toggleContent = (e) => {
    e.preventDefault();
    if (selected) {
      setIsContentVisible(true);
      setShouldScroll(true);
    }
    else{
      alert("Please Enter a valid address")
    }
  };
  useEffect(() => {
    if (shouldScroll && MapRef.current) {
      MapRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setShouldScroll(false); // Reset the flag after scrolling
    }
  }, [shouldScroll]);
  const [flag, setFlag] = useState(false);

  const handlePickLocation = async () => {
    console.log("Picked Location:", markerPosition);
    // setFlag(true);
    setFlag(true);
    const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${markerPosition.lat},${markerPosition.lng}&zoom=20&size=600x400&maptype=satellite&markers=${markerPosition.lat},${markerPosition.lng}&key=AIzaSyCc1UvL8wAY16Q8A6wgq1MGfvRwpTS0zK8`;
    dispatch(setImage(staticMapUrl));
    // console.log(staticMapUrl);
    const mapsLink = `https://www.google.com/maps?q=${markerPosition.lat},${markerPosition.lng}`;
    console.log(mapsLink);
    setLocation({
      ...location,
      link: mapsLink,
      lat: markerPosition?.lat,
      lng: markerPosition?.lng,
    });
  };
  useEffect(() => {
    // Perform side effects here
    if (flag) {
      setIsFormVisible(true);
      setIsContentVisible(false);
    }
  }, [flag]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CustomMarker = ({ position, text }) => (
    <div onClick={handlePickLocation}>
      {/* <p style={{backgroundColor:'grey'}}>{text}</p> */}
      <button
        // onClick={()=>console.log('clicked')}
        style={{
          backgroundColor: "green",
          fontSize: "10px",
          fontWeight: "bold",
          // top:-20,
          // left:-20,
          // padding: "5px",
          // margin:'20px',
          height: "40px",
          width: "80px",
          borderRadius: "20px",
          color: "white",
        }}
        // onClick={handlePickLocation}
      >
        Confirm
      </button>
    </div>
  );

  // const test = {
  //   path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
  //   fillColor: "red",
  //   fillOpacity: 2,
  //   strokeWeight: 1,
  //   rotation: 0,
  //   scale: 1,
  // };
  const LocationIcon = locPic;

  const [selectedFile, setSelectedFile] = useState(null);
  const [formScroll, setFormScroll] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      toast.success("BluePrint uploaded");
      setIsContentVisible(false);
      setIsFormVisible(true);
      setFormScroll(true);
      // FormRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setSelectedFile(null);
      toast.error("error while uploading file");
    }
  };

  useEffect(() => {
    if (formScroll && FormRef.current) {
      FormRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setFormScroll(false); // Reset the flag after scrolling
    }
  }, [formScroll]);
  // const handlebuluePrints = () => {};

  const handleClickableAreaClick = () => {
    // Handle the click event for the specific area
    alert("Clickable area clicked!");
  };
  return (
    <>
      <LoadScript
        // googleMapsApiKey="AIzaSyBdz3QPUZHzSr7-IDT6WSwNQjPW6VRhSYA"
        googleMapsApiKey="AIzaSyCc1UvL8wAY16Q8A6wgq1MGfvRwpTS0zK8"
        libraries={libraries}
      >
        <div className="Banner bg-cover bg-center bg-no-repeat mx-auto relative">
          <div
            style={{ margin: "0 auto" }}
            className="flex-container max-w-1200px"
          >
            <div
              style={{ marginTop: "-100px" }}
              className="flex-item max-w-7xl mx-auto"
            >
              <div className="partition mx-20 my-5">
                <div className="mx-15 my-5">
                  <h1
                    className="banner-title text-white font-bold"
                    variant="h2"
                    gutterBottom
                  >
                    ORDER NOW
                  </h1>
                  <Typography
                    style={{ marginBottom: "15px" }}
                    className="font-bold text-white"
                  >
                    Search for the address or simply upload blueprint to get
                    your estimate
                  </Typography>

                  <form onSubmit={toggleContent}>
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="input-container">
                          {/* <span className="icon">
                          <LocationOnIcon sx={{ color: "#f36e21" }} />
                        </span> */}
                          <input
                            {...getInputProps({
                              placeholder: "Search Places...",
                              className: "styled-input",
                            })}
                            required
                          />
                          <div
                            style={{
                              position: "absolute",
                              border: "1px solid black",
                              backgroundColor: "white",
                              borderRadius: "10px",
                            }}
                            className="autocomplete-dropdown-container"
                          >
                            {/* {loading && <div>Loading...</div>} */}
                            {suggestions.map((suggestion) => {
                              const style = {
                                // textAlign:'center',
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                // marginLeft:'20px',
                                borderRadius: "10px",
                                cursor: "pointer",
                                backgroundColor: suggestion.active
                                  ? "#41b6e6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  style={{ position: "relative" }}
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>

                    <br />
                    {/* <Link to="section1" smooth={true} duration={500}> */}
                    <button
                      style={{ fontWeight: "bold" }}
                      type="submit"
                      // onClick={toggleContent}
                      className="loc-btn"
                    >
                      Search
                    </button>

                    <label className="loc-btn">
                      <span>Upload blueprint</span>
                      {selectedFile ? (
                        <>
                          {" "}
                          |{" "}
                          {`${selectedFile.name.substring(0, 3)}.. ${
                            selectedFile.name.includes(".")
                              ? `.${selectedFile.name.split(".").pop()}`
                              : ""
                          }`}
                        </>
                      ) : null}

                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        className="hidden"
                        // ref={fileInputRef}
                        onChange={handleFileChange}
                        // onChange={handleFileUpload}
                        // onClick={handlebuluePrints}
                      />
                    </label>
                  </form>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Cards /> */}

        {isContentVisible && (
          <div ref={MapRef}>
            <GoogleMap
              mapContainerStyle={{
                margin: "0 auto",
                marginTop: "80px",
                marginBottom: "50px",
                height: "500px",
                maxWidth: "1200px",
              }}
              zoom={20}
              center={markerPosition}
              mapTypeId={mapTypeId} // Set the map type to satellite
              onClick={(e) =>
                setMarkerPosition({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                })
              }
            >
              {/* <OverlayView
                draggable={true}
                position={markerPosition}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                onClick={handlePickLocation}
              >
                <CustomMarker
                  position={markerPosition}
                  text="Confirm Location"
                  onClick={handlePickLocation}
                />
              </OverlayView> */}
              <Marker
                position={markerPosition}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
                onClick={handlePickLocation}
                icon={LocationIcon}
              >
                {/* <CustomClickableArea
                  position={markerPosition - 1}
                  onClick={handlePickLocation}
                /> */}
                {/* {(props) => <CustomMarker {...props} text="Select location" />}z */}
              </Marker>
            </GoogleMap>
          </div>
        )}

        {/* <button onClick={handlePickLocation}>Pick Location</button> */}
      </LoadScript>

      {/* {location && ( */}

      <div
        className={`content ${isFormVisible ? "visible" : "hidden"}`}
        ref={FormRef}
      >
        <Form location={location} file={selectedFile} />
      </div>

      {/* )} */}
    </>
  );
}
