import React from "react";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import {Routes,Route} from "react-router-dom";
/** import all components */
import Username from "./components/Username";
import Password from "./components/Password";
import Register from "./components/Register";
// import Profile from "./components/Profile";
import Recovery from "./components/Recovery";
import Reset from "./components/Reset";
import PageNotFound from "./components/PageNotFound";
import Bar from "./components/header/Bar";
// import Banner from "./components/Banner/Banner";
import ProductCatalog from "./components/productCatalog/ProductCatalog";

/** auth middleware */
import { AuthorizeUser, ProtectRoute } from "./middleware/auth";
import Footer from "./components/navigation/Footer";
import AboutUs from "./components/Intro/Intro";
import Services from "./components/Services/Services";
import Slider from "./components/Slider/Slider";
// import Slider2 from "./components/Slider/Slider2";
import Form from "./components/InfoForm/Form";
import NavBar from "./components/navigation/NavBar";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./Pages/ContactUs";
import Landing from "./Pages/Landing";
import About from "./Pages/About";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import Product from "./Pages/Products";
import Profile from "./Pages/Profile";
import Dummy from "./Pages/Dummy";
import Cart from "./components/Cart/Cart";
import PriceModel from "./components/Pricing/PriceModel";
import RegisterNPayment from "./components/payment/RegisteNPayment";
import LoginNPayment from "./components/payment/LoginNPayment";
import MyGoogleMap from "./components/map/MyGoogleMap";
import TermsNConditions from "./components/TCNPP/Terms";
import PrivacyPolicy from "./components/TCNPP/PrivacyPolicy";
import FAQs from "./Pages/FAQs";
import FileUploadComponent from "./components/FileUploadComponent";
import Test from "./components/InfoForm/Test";
import EditProducts from "./components/EditProducts/EditProducts";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Success from "./components/Cart/Success";

// import GoogleMapComponent from "./components/map/GoogleMapComponent";

/** root routes */
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Username></Username>,
//   },
//   {
//     path: "/register",
//     element: <Register></Register>,
//   },
//   {
//     path: "/password",
//     element: (
//       <ProtectRoute>
//         <Password />
//       </ProtectRoute>
//     ),
//   },
//   {
//     path: "/profile",
//     element: (
//       <AuthorizeUser>
//         <Profile />
//       </AuthorizeUser>
//     ),
//   },
//   {
//     path: "/recovery",
//     element: <Recovery></Recovery>,
//   },
//   {
//     path: "/reset",
//     element: <Reset></Reset>,
//   },
//   {
//     path: "*",
//     element: <PageNotFound></PageNotFound>,
//   },
// ]);

export default function App() {
  return (
    <main className="App">
      {/* <Bar /> */}
      {/* <MyGoogleMap /> */}
      {/* <NavBar /> */}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Product />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/pricing" element={<PriceModel />} />
        <Route path="/register&payment" element={<RegisterNPayment />} />
        <Route path="/login&payment" element={<LoginNPayment />} />
        <Route path="/map" element={<MyGoogleMap />} />
        <Route path="/terms&conditions" element={<TermsNConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/test" element={<FileUploadComponent />} />
        <Route path="/test2" element={<Test />} />
        <Route path="/edit-products" element={<EditProducts />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
        <Route
          path="/abuhfjenfdjdjfhiuujfdgmioicjkncyudhh9tjifsajkkxvtytaggdd98efjew33383883838bhjdbbvggfiudnlkdsbcjkhbhjdnglkdsnvjkvchuxbvkjxvvnxvhdufgb"
          element={<Success />}
        />

        <Route path="*" element={<Dummy />} />
      </Routes>
      {/* <RouterProvider router={router}></RouterProvider> */}
      {/* <Slider2 /> */}
      {/* <Footer /> */}
    </main>
  );
}
