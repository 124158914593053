import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import BASE_URL from "../../services/BASE_URL";
import { useSelector } from "react-redux";
import NavBar from "../navigation/NavBar";
import Footer from "../navigation/Footer";

const Success = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const orderIds = useSelector((state) => state.orderIds.orderIds);
  // console.log(orderIds)
  // Assuming you have an array of order IDs

  // Define the payment status you want to update for each order
  const payement = "paid";

  // Loop through each order ID and send update request
  const updateOrders = async () => {
    let success = true; // Flag to track success of API requests
  
    for (let orderId of orderIds) {
      try {
        const response = await axios.put(
          `${BASE_URL}/orders/update-payment/${orderId}`,
          { payement }
        );
        console.log(
          `Payment status updated for order ${orderId}`,
          response.data
        );
      } catch (error) {
        console.error(
          `Error updating payment status for order ${orderId}`,
          error
        );
        success = false; // If any API request fails, set success flag to false
      }
    }
  
    // After all updates are done, navigate to /profile if all requests were successful
    if (success) {
      navigate("/profile");
    } else {
      // Handle error scenario if needed
      console.error("Some API requests failed, unable to navigate to /profile");
    }
  };
  
  // console.log(abc);
  useEffect(() => {
    updateOrders();
  }, []); // Include navigate in the dependency array

  return (
    <>
      <NavBar />
      <div>
        <h1>Navigating to dashboard</h1>
        {/* You can add any UI elements or messages here */}
      </div>
      <Footer />
    </>
  );
};

export default Success;
