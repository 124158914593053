import React, { useEffect } from "react";
import NavBar from "../navigation/NavBar";
import Bar from "../header/Bar";
import Footer from "../navigation/Footer";
import "../globalStyles/global.css";
import ScrollToTopOnRefresh from "../ScrollToTopOnRefresh";

export default function Terms() {
  const data = [
    {
      heading: "Client Personal Information:",
      content:
        "MAT Estimates values the confidentiality of client personal information. We adhere to a strict policy wherein we do not sell, rent, share, or disclose client personal information to third parties without explicit consent. By using our website, clients acknowledge and consent to the collection, processing, and storage of personal information as outlined in our Privacy Policy. Personal information, including but not limited to name, contact details and payment information, is securely managed and treated with the utmost confidentiality.",
    },
    {
      heading: "Payments:",
      content:
        "Clients have to provide accurate and complete payment information for any services or products purchased from MAT Estimates. Payments are processed securely, and credit card information is not retained on our website. MAT Estimates reserves the right to update pricing and payment terms. Any changes will be communicated to clients in advance. Invoices for services or products are payable within the specified timeframe. Late payments may result in the suspension of services. Please note that MAT Estimates utilizes a third-party payment platform to process transactions. While we prioritize the security of your financial information, it's important to acknowledge that we do not guarantee or warranty the actions or security measures of the third-party payment platform. Clients are encouraged to review and understand the terms and security practices of the third-party payment platform to ensure a comprehensive understanding of the payment process.",
    },
    {
      heading: "User Account Security:",
      content:
        "We prioritize the protection of your information and want to assure you that your account’s data is secured with our website. Unauthorized access to client accounts should be reported to MAT Estimates immediately. Clients are advised to use strong passwords and to refrain from sharing their account credentials to further enhance the security of their personal information.",
    },
    {
      heading: "Third-Party Involvement and Data Usage:",
      content:
        "At MAT Estimates, we want to ensure transparency regarding the involvement of third parties in certain aspects of our services. By using our platform, you acknowledge that your information may be utilized by third-party services such as Google Maps for location-based services, payment platforms for secure transactions, and material providers for specific project needs. While we prioritize the security of your data, it's essential to be aware that these third parties may have their own privacy policies. We recommend reviewing the terms and conditions of these third-party services to understand how your information may be handled. If you have any concerns or questions, feel free to reach out to our customer service team for clarification.",
    },
    {
      heading: "Pricing Variability:",
      content:
        "MAT Estimates strives to provide accurate and transparent pricing for its services and products. Clients acknowledge that the final price for a report may vary upon delivery, depending on any changes in the size of the project. If there are substantial changes in the project size or requirements that affect the pricing, MAT Estimates will communicate these changes to the client in advance. MAT Estimates is committed to fairness and transparency in pricing adjustments, ensuring clients are informed and agreed to any modifications before the final delivery of the report. This clause aims to address potential variations in the pricing of reports based on changes in project size, emphasizing communication and agreement between MAT Estimates and the client regarding any adjustments.",
    },
    {
      heading: "Cancellation and Refund Policy:",
      content:
        "Our Cancellation and Refund Policy at MAT Estimates aims to provide a hassle-free experience for our customers. You can cancel your order without any penalties within a specified time frame, allowing flexibility for changes in your purchase decisions. The cancellation window is one hour from the time of placing the order, providing an opportunity to review and adjust without incurring fees. To cancel, contact our customer service team or log in to MyAccount, go to MyOrders, and hit cancel. If you cancel within the 1-hour period, we'll process a full refund either to your MAT wallet or the original payment method. However, please note that after this 1-hour window, orders are considered final and cannot be canceled or refunded. This policy ensures the timely processing of orders and helps maintain efficiency in our operations.",
    },
    {
      heading: "Intellectual Property:",
      content:
        "All content on the MAT Estimates website, including text, graphics, and logos, is protected by intellectual property laws. Clients may not reproduce, distribute, or use any content from the website without explicit permission from MAT Estimates.",
    },
    {
      heading: "Termination of Services:",
      content:
        "MAT Estimates reserves the right to terminate or suspend services for clients who violate these terms and conditions or engage in activities deemed unlawful. Termination of services does not relieve clients of their financial obligations for services rendered.",
    },
    {
      heading: "Modifications to Terms and Conditions:",
      content:
        "MAT Estimates reserves the right to modify the provisions of this agreement at any time and for any reason, with such changes taking effect upon their posting on the website. These modifications may be implemented without prior or contemporaneous notice to you. It is your responsibility, and by using our website, you agree to review and confirm your understanding of this agreement each time you engage with our services. While MAT Estimates may provide notifications of changes periodically, you may also be prompted to acknowledge that you have read and comprehend our updated terms and conditions when placing an order. We appreciate your ongoing diligence in staying informed about our policies.",
    },
    {
      heading: "Contact Information:",
      content:
        "For inquiries or concerns regarding these terms and conditions, clients may contact MAT Estimates through the provided channels on our website. By using MAT Estimates' website and services, clients acknowledge that they have read, understood, and agreed to these terms and conditions. Failure to comply with these terms may result in the termination of access to MAT Estimates' services.",
    },
  ];

  const a = "home > Terms&Conditions";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Bar />
      <NavBar />
      <ScrollToTopOnRefresh />
      <div
        style={{
          borderTop: "2px solid black",
          height: "70px",
          backgroundColor: "#f36e21",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Terms&Conditions</h1>
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>

      <div style={styles.container}>
        <h1
          style={{ textAlign: "center", alignItems: "center" }}
          className="heading1"
        >
          Terms and Conditions
        </h1>
        <p>
          This document represents the official "Terms of Use" or "User
          Agreement" between you and MAT Estimates. Before engaging with any of
          MAT Estimates' websites, participating in transactions, or utilizing
          any information provided by MAT Estimates, you explicitly acknowledge
          that you have thoroughly read and comprehended this agreement and are
          in full agreement with its terms. MAT Estimates, represented as "we"
          or "us" in this agreement, operates as a reputable entity in
          accordance with relevant legal standards. As the user of our
          website(s), you are referred to as "you." By accessing and utilizing
          any website operated by MAT Estimates, regardless of the method of
          access, you confirm and consent to abide by all the statements, terms,
          and conditions outlined in this agreement, collectively referred to as
          "provisions." Should there be any disagreement with any provision of
          this agreement, your authorization to use MAT Estimates’ websites,
          products, or services is nullified. In the event of dissatisfaction
          with the performance of our website, products, or services, your
          exclusive recourse is to cease usage.
        </p>

        {data.map((item, index) => (
          <div key={index}>
            <h4
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                marginTop: "10px",
              }}
              className="heading4"
            >
              {item.heading}
            </h4>
            <p>{item.content}</p>
          </div>
        ))}

        {/* Add more sections as needed */}
      </div>
      <Footer />
    </>
  );
}

const styles = {
  container: {
    maxWidth: "1100px",
    margin: "0 auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    textAlign:'justify'
  },
};
