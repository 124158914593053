import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import Gutter from "../../assets/Catalog-logos/MATGUTTER.svg";
import Conc from "../../assets/Catalog-logos/Concrete.svg";
import Paiter from "../../assets/Catalog-logos/Painter.svg";
import Roof from "../../assets/Catalog-logos/MATROOFLogo.svg";
import RoofX from "../../assets/Catalog-logos/MATROOFAlphaLogo.svg";
import SideX from "../../assets/Catalog-logos/MATSIDEX.svg";
import "./styles.css";
export default function productCatalog() {
  return (
    <>
      <div className="catalog-cont">
        <Grid container>
          <Grid item sm={12} lg={12}>
            <Typography
              variant="h4"
              style={{
                textAlign: "center",
                color: "#f36e21",
                fontWeight: "bold",
                fontSize: "50px",
                margin:'20px'
              }}
            >
              Our Product Catalogue
            </Typography>
            {/* <p
              style={{
                textAlign: "center",
                maxWidth: "800px",
                margin: "0 auto",
              }}
            >
              At MAT Estimates, we don't just provide numbers; we provide the
              foundation for your project's success. Explore our services and
              let us be your partner in achieving accurate and reliable
              estimates for your construction endeavors.
            </p> */}
            {/* <img src='/Fuc-Facts-overlay.jpg' alt="pic" /> */}
          </Grid>

          <Grid item sm={6} md={6} lg={4} p={4}>
            <div style={{}}>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: "" }}
            >
              <img
                style={{ height: 50, marginBottom: 10 }}
                src={Roof}
                alt="pic"
              />
              {/* <p style={{ fontWeight: "bold" }}>MAT Roof</p> */}
            </div>
            <ul style={{ listStyleType: "disc", textAlign:'justify'  }}>
              <li>Comprehensive Roof Details Report</li>
              <li> Includes Dimensions, Pitch, and Area</li>
              <li> Individual Roof Plans for Each Structure</li>
            </ul>
            </div>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Doloribus a nesciunt rem vel unde et praesentium corrupti
              molestias aspernatur alias consequatur perferendis culpa mollitia
              quis minus, id aliquid assumenda nam.
            </p> */}
          </Grid>
          <Grid item sm={6} md={6} lg={4} p={4}>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 0 }}
            >
              <img
                style={{ height: 50, marginBottom: 10 }}
                src={RoofX}
                alt="pic"
              />
              {/* <p style={{ fontWeight: "bold" }}>Mat Roof alpha</p> */}
              {/* <br /> */}
            </div>
            <ul style={{ listStyleType: "disc"  }}>
              <li>Specialized Roof Report</li>
              <li>Focuses on Square Area (SQ) of the Roof</li>
              <li>Streamlined for Quick Reference</li>
            </ul>
          </Grid>
          <Grid item sm={6} md={6} lg={4} p={4}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img style={{ height: 50, margin: 3 }} src={Gutter} alt="pic" />
              {/* <p style={{ fontWeight: "bold" }}>Mat Gutter</p> */}
              {/* <br /> */}
            </div>
            <ul style={{ listStyleType: "disc"  }}>
              <li>Gutter Estimation Report</li>
              <li>Detailed Analysis for Precise Gutter Planning</li>
              <li>Ensures Efficient Water Management</li>
            </ul>
          </Grid>
          <Grid item sm={6} md={6} lg={4} p={4}>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 0 }}
            >
              <img
                style={{ height: 50, marginBottom: 10 }}
                src={SideX}
                alt="pic"
              />
              {/* <p style={{ fontWeight: "bold" }}>Mat Side X</p> */}
              <br />
            </div>
            <ul style={{ listStyleType: "disc"  }}>
              <li> Siding Estimation Report</li>
              <li> Provides Accurate Measurements and Material Requirements</li>
              <li>Enhances Aesthetics and Durability</li>
            </ul>
          </Grid>
          <Grid item sm={6} md={6} lg={4} p={4}>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 0 }}
            >
              <img
                style={{ height: 50, marginBottom: 10 }}
                src={Paiter}
                alt="pic"
              />
              {/* <p style={{ fontWeight: "bold" }}>Mat Painter</p> */}
              {/* <br /> */}
            </div>
            <ul style={{ listStyleType: "disc"  }}>
              <li>Paint Estimation Report</li>
              <li>Factors in Surface Type, Paint Quality, and More</li>
              <li>Aids in Achieving the Perfect Finish</li>
            </ul>
          </Grid>
          <Grid item sm={6} md={6} lg={4} p={4}>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 0 }}
            >
              <img
                style={{ height: 50, marginBottom: 10 }}
                src={Conc}
                alt="pic"
              />
              {/* <p style={{ fontWeight: "bold" }}>Mat Concrete</p> */}
              {/* <br /> */}
            </div>
            <ul style={{ listStyleType: "disc"  }}>
              <li> Concrete Estimation Report</li>
              <li>Covers Foundations to Pavements</li>
              <li>Detailed Breakdown of Materials and Labor</li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
