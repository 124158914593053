import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Countup from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./aboutcards.css";
import { useState } from "react";

export default function AboutCard() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <>
        {" "}
        <div style={{ marginBottom: "20px", marginTop: "30px" }}>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "50px",
              textAlign: "center",
              color: "#f36e21",
            }}
          >
            What sets us Apart
          </p>
          {/* <p
            style={{ textAlign: "center", maxWidth: "800px", margin: "0 auto" }}
          >
            More details and data points in fewer pages <br /> Services like
            Text-to-Scope for easy ordering, updates &
          </p> */}
        </div>
        <Grid container maxWidth={"1000px"} margin={"0 auto"}>
          <Grid item xs={6} sm={6} lg={3}>
            <Card className="about-cards">
              <div style={{ margin: "0 auto", padding: "60px" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {counterOn && (
                    <Countup start={0} end={100} duaration={3} delay={0} />
                  )}
                  +
                </p>{" "}
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  Satisfied <br /> Customers
                </p>
              </div>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} lg={3}>
            <Card className="about-cards">
              <div style={{ margin: "0 auto", padding: "60px" }}>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {counterOn && (
                    <Countup start={0} end={2} duaration={3} delay={0} />
                  )}
                  k+
                </p>{" "}
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  Completed <br /> Orders{" "}
                </p>
              </div>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} lg={3}>
            <Card className="about-cards">
              <div style={{ margin: "0 auto", padding: "60px" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {counterOn && (
                    <Countup start={0} end={2} duaration={4} delay={0} />
                  )}
                  hrs
                </p>{" "}
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  Delivery <br /> Time
                </p>
              </div>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} lg={3}>
            <Card className="about-cards">
              <div style={{ margin: "0 auto", padding: "60px" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {counterOn && (
                    <Countup start={0} end={97} duaration={3} delay={0} />
                  )}
                  %
                </p>{" "}
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  Accuracy <br /> Guaranted
                </p>
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    </ScrollTrigger>
  );
}
