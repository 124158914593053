import React from "react";
import AbouPic from "../../assets/About-us.png";
import "./style.css";
import { Grid } from "@mui/material";

export default function Intro() {
  return (
    <>
      {/* <h1
        style={{
          
          fontWeight: "bold",
          fontSize: "40px",
          textAlign:'center',
          marginTop:'20px'
        }}
      >
        About MAT estimates
      </h1> */}
      {/* <Grid container maxWidth={"1200px"} margin={"0 auto"}>
        <Grid item sm={12} lg={6}>
          <img
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
            className="about-us-pic-overlay"
            src={AbouPic}
            alt="AboutPic"
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <div>
            <h1
              style={{
                //   height: "70vh",
                //   padding: "10vh",
                fontWeight: "bold",
                fontSize: "40px",
              }}
            >
              About MAT estimates
            </h1>
            <h2
              style={{ fontWeight: "bold", fontSize: "20px", margin: "20px" }}
            >
              MAt Estimate is just $11 per report
            </h2>
            <p style={{ margin: "20px" }}>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Explicabo eligendi culpa voluptatem, nemo quaerat aliquam ea autem
              voluptate quisquam odit, ipsa voluptatibus repellat sapiente
              velit. Incidunt nemo eos deleniti maxime. Lorem ipsum dolor sit
              amet consectetur, adipisicing elit. Explicabo eligendi culpa
              voluptatem, nemo quaerat aliquam ea autem voluptate quisquam odit,
              ipsa voluptatibus repellat sapiente velit. Incidunt nemo eos
              deleniti maxime. Explicabo eligendi culpa voluptatem, nemo quaerat
              aliquam ea autem voluptate quisquam odit, ipsa voluptatibus
              repellat sapiente velit. Incidunt nemo eos deleniti maxime.
              Explicabo eligendi culpa voluptatem, nemo quaerat aliquam ea autem
              voluptate quisquam odit, ipsa voluptatibus repellat sapiente
              velit. Incidunt nemo eos deleniti maxime.
            </p>
          </div>
        </Grid>
      </Grid> */}
      <div style={{marginTop:'40px'}} className="about-us-cont">
        <img className="about-us-pic-overlay" src={AbouPic} alt="AboutPic" />
        <div>
          <h1
            style={{
              //   height: "70vh",
              //   padding: "10vh",
              fontWeight: "bold",
              fontSize: "40px",
              marginLeft:'15px',
              color:'#f36e21'
            }}
            className=""
          >
            About Us
          </h1>
          {/* <h2 style={{ fontWeight: "bold", fontSize: "20px", margin: "20px" }}>
            MAt Estimate is just $11 per report
          </h2> */}
          <p style={{ margin: "20px", textAlign:'justify' }}>
            Welcome to MAT Estimates, your reliable partner in accurate and
            comprehensive estimation services. As a dedicated estimation
            company, we specialize in providing detailed take-off reports for
            various construction elements, ensuring that you have the precise
            information you need for your projects. Our expertise covers a range
            of areas, including:
            <br />
            <span class="check-icon">✔</span> Roof <br />
            <span class="check-icon">✔</span> Gutters <br />
            <span class="check-icon">✔</span> Paint <br />
            <span class="check-icon">✔</span> Concrete <br />
            <span class="check-icon">✔</span> Siding <br />
            At MAT Estimates, we understand the critical role that accurate
            estimates play in the success of construction projects. With years
            of experience in the industry, we have honed our skills to become a
            trusted name in estimation services.
          </p>
        </div>
      </div>
    </>
  );
}
